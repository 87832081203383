import * as Yup from 'yup';

// TYPES
import { ConsultarMutuarioData } from 'store/modules/api/cdhu/consultarMutuario/types';
import {
	CalculoBoletoAgrupadoRequest,
	GerarOcorrenciaData,
	// GerarOcorrenciaRequest
} from 'store/modules/api/cdhu/gerarOcorrencia/types';
import {
	GerarBoletoAgrupadoRequest,
	// GerarBoletoAgrupadoData,
} from 'store/modules/api/cdhu/gerarBoletoAgrupado/types';
import { ConsultaPrestacoesAtrasadasData } from 'store/modules/api/cdhu/consultarPrestacoesAtrasadas/types';
import { limparMascara, unformatDate } from 'utils/genericFunctions';

export interface IFormOpcoes {
	opcao1: boolean;
	opcao2: boolean;
	qtdPrestacoes: number;
	tempQtdPrestacoes: number;
	prestacoes: number | string;
}

export const initialValues: IFormOpcoes = {
	opcao1: false,
	opcao2: false,
	qtdPrestacoes: 0,
	tempQtdPrestacoes: 0,
	prestacoes: '',
};

export const schema = Yup.object<IFormOpcoes>().shape({
	opcao: Yup.string().when(['opcao1', 'opcao2'], {
		is: (opcao1, opcao2) => !opcao1 && !opcao2,
		then: Yup.string().required(
			'Campo Obrigatório: Escolha uma opção antes de prosseguir.',
		),
	}),
	pagamentoParcial: Yup.string().when(['opcao2', 'qtdPrestacoes'], {
		is: (opcao2, qtdPrestacoes) => opcao2 && qtdPrestacoes === 0,
		then: Yup.string().required(
			'Campo Obrigatório: Informe a quantidade de prestações.',
		),
	}),
});

function handleDataVencimento(data: string): string {
	const ano = limparMascara(data).substring(0, 4);
	const mes = limparMascara(data).substring(4, 6);
	return mes + ano;
}

export function treatValues(
	formValues: IFormOpcoes,
	mutuario: ConsultarMutuarioData,
	prestacoesAtrasadas: ConsultaPrestacoesAtrasadasData,
	gerarOcorrencia: GerarOcorrenciaData,
	// dataVencimentoBoletoAgrupado: string,
	calculoBoletoAgrupado: CalculoBoletoAgrupadoRequest,
): GerarBoletoAgrupadoRequest {
	// const { opcao1 } = formValues;
	const { ocorrenciaId } = gerarOcorrencia;
	const { prestacoesAtField } = prestacoesAtrasadas;
	const { numeroContrato } = mutuario;
	const { dataVencimento } = calculoBoletoAgrupado;
	debugger

	const dataInicio =
		prestacoesAtField.length === 0
			? ''
			: handleDataVencimento(prestacoesAtField[0].dataVencimentoField);

	let dataFim;

	if (prestacoesAtField.length === 0) {
		dataFim = '';
	} else if (formValues.prestacoes === '' && formValues.qtdPrestacoes === 0) {
		dataFim = handleDataVencimento(prestacoesAtField[0].dataVencimentoField);
	} else if (formValues.prestacoes === '' && formValues.qtdPrestacoes !== 0) {
		dataFim = handleDataVencimento(
			prestacoesAtField[formValues.qtdPrestacoes - 1].dataVencimentoField,
		);
	} else if (formValues.prestacoes !== '' && formValues.qtdPrestacoes !== 0) {
		dataFim = handleDataVencimento(
			prestacoesAtField[Number(formValues.prestacoes) - 1].dataVencimentoField,
		);
	} else {
		dataFim = '';
	}

	return {
		servicoEnum: 'BOLETO_AGRUPADO',
		ocorrenciaId,
		numeroContrato: Number(numeroContrato),
		dataInicio,
		dataFim,
		dataLimite: unformatDate(dataVencimento),
	};
}
