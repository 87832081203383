/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Field, FormikProps } from 'formik';
import { Col, Row } from 'antd';

import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import Select from 'components/Common/Form/Select';
import RG from 'components/Common/Form/Fields/RG';

import dataEnum from 'store/modules/api/educacaoUpdate/enum';
import data from 'store/modules/enum';

import hasError from 'utils/getFormErrors';
import FormBox from 'components/Common/Form/FormBox';
import { IFichaAluno } from '../form';

import { ContainerFichaCadastral } from '../styled';
import { Container, Divider } from './styled';

interface Props {
	formik: FormikProps<IFichaAluno>;
}

const Documentos: React.FC<Props> = ({ formik }) => {
	useEffect(() => {
		if (formik.values.certidaoAntiga.numeroCertidao === '') {
			formik.setFieldValue('tipo', '');
		} else if (formik.values.certidaoAntiga.numeroCertidao !== '') {
			formik.setFieldValue('tipo', 'ANTIGA');
		} else {
			formik.setFieldValue('tipo', 'NOVA');
		}
	}, [formik.values.certidaoAntiga.numeroCertidao]);

	useEffect(() => {
		if (formik.values.documentos.tipoCertidao === undefined) {
			formik.setFieldValue('documentos.tipoCertidao', '');
		} else if (formik.values.documentos.tipoCertidao !== '') {
			if (formik.values.documentos.tipoCertidao === 'ANTIGA') {
				formik.setFieldValue('certidaoAntiga.tipoCertidao', 'ANTIGA');
				formik.setFieldValue('certidaoNova.tipoCertidao', '');
			} else if (formik.values.documentos.tipoCertidao === 'NOVA') {
				formik.setFieldValue('certidaoNova.tipoCertidao', 'NOVA');
				formik.setFieldValue('certidaoAntiga.tipoCertidao', '');
			}
		} else {
			formik.setFieldValue('certidaoAntiga.tipoCertidao', '');
			formik.setFieldValue('certidaoNova.tipoCertidao', '');
		}
	}, [formik.values.documentos.tipoCertidao]);

	return (
		<ContainerFichaCadastral>
			<FormBox title="Documentos">
				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							title="CPF"
							name="documentos.cpf"
							mask="999.999.999-99"
							as={InputMask}
							error={
								hasError(formik.errors, 'documentos.tipoCertidao') ||
								hasError(formik.errors, 'documentos.cpf')
							}
						/>
					</Col>

					<Col span={11} offset={1}>
						<Field
							as={InputMask}
							mask="99999999999"
							title="NIS"
							name="documentos.numeroNIS"
							error={
								hasError(formik.errors, 'documentos.tipoCertidao') ||
								hasError(formik.errors, 'documentos.numeroNIS')
							}
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={12}>
						<RG
							title="Documento Civil RG"
							numero="documentos.numeroDocumentoCivil"
							digito="documentos.digitoDocumentoCivil"
							defaultNumero={
								formik.initialValues.documentos.numeroDocumentoCivil
									? formik.initialValues.documentos.numeroDocumentoCivil.replace(
											/\b0+/g,
											'',
									  )
									: ''
							}
							defaultDigito={
								formik.initialValues.documentos.digitoDocumentoCivil
							}
							formik={formik}
							result={rg => {
								formik.setFieldValue(
									'documentos.numeroDocumentoCivil',
									rg.numeroRg,
								);
								formik.setFieldValue(
									'documentos.digitoDocumentoCivil',
									rg.digitoRg,
								);
							}}
							error={
								hasError(formik.errors, 'documentos.tipoCertidao') ||
								hasError(formik.errors, 'documentos.numeroDocumentoCivil')
							}
						/>
					</Col>

					<Col span={11} offset={1}>
						<Field
							as={Select}
							title="UF RG"
							name="documentos.ufDocumentoCivil"
							options={data.uf}
							onChange={(v: string) =>
								formik.setFieldValue('documentos.ufDocumentoCivil', v)
							}
							error={hasError(formik.errors, 'documentos.ufDocumentoCivil')}
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={ValidDataInput}
							title="Data Emissão RG/RNE"
							name="documentos.dataEmissaoDocumentoCivil"
							mask="99/99/9999"
							type="text"
							error={hasError(
								formik.errors,
								'documentos.dataEmissaoDocumentoCivil',
							)}
							formik={formik}
							onChange={(v: string) =>
								formik.setFieldValue('documentos.dataEmissaoDocumentoCivil', v)
							}
						/>
					</Col>

					<Col span={11} offset={1}>
						<Field
							title="Data Entrada no Brasil"
							name="dadosPessoais.dataEntradaPais"
							type="text"
							mask="99/99/9999"
							as={ValidDataInput}
							error={hasError(formik.errors, 'dadosPessoais.dataEntradaPais')}
							formik={formik}
							onChange={(v: string) =>
								formik.setFieldValue('dadosPessoais.dataEntradaPais', v)
							}
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={12}>
						<Field
							as={Select}
							title="Tipo de Certidão"
							name="documentos.tipoCertidao"
							value={formik.values.documentos.tipoCertidao}
							options={dataEnum.tipoCertidao}
							onChange={(v: string) =>
								formik.setFieldValue('documentos.tipoCertidao', v)
							}
							error={hasError(formik.errors, 'documentos.tipoCertidao')}
						/>
					</Col>
					{formik.values.documentos?.tipoCertidao === 'NOVA' ? (
						<Col span={11} offset={1}>
							<Field
								title="Data de emissão"
								name="certidaoNova.dataEmissaoCertidao"
								type="text"
								mask="99/99/9999"
								as={ValidDataInput}
								error={hasError(
									formik.errors,
									'certidaoNova.dataEmissaoCertidao',
								)}
								formik={formik}
								onChange={(v: string) =>
									formik.setFieldValue('certidaoNova.dataEmissaoCertidao', v)
								}
								required
							/>
						</Col>
					) : (
						<Col span={11} offset={1}>
							<Field
								title="Data de emissão"
								name="certidaoAntiga.dataEmissaoCertidao"
								type="text"
								mask="99/99/9999"
								as={ValidDataInput}
								error={hasError(
									formik.errors,
									'certidaoAntiga.dataEmissaoCertidao',
								)}
								formik={formik}
								onChange={(v: string) =>
									formik.setFieldValue('certidaoAntiga.dataEmissaoCertidao', v)
								}
								required
							/>
						</Col>
					)}
				</Row>
				{formik.values.documentos.tipoCertidao === '' && ''}
				{formik.values.documentos.tipoCertidao === 'NOVA' && (
					<>
						<Row gutter={[0, 10]}>
							<Container>
								<Col span={5}>
									<Field
										as={InputMask}
										mask="999999"
										title="Matricula"
										name="certidaoNova.certificadoMatricula01"
										error={hasError(
											formik.errors,
											'certidaoNova.certificadoMatricula01',
										)}
										required
									/>
								</Col>
								<Col span={1}>
									<Field
										as={InputMask}
										mask="99"
										name="certidaoNova.certificadoMatricula02"
										error={hasError(
											formik.errors,
											'certidaoNova.certificadoMatricula01',
										)}
										required
									/>
								</Col>
								<Col span={1}>
									<Field
										as={InputMask}
										mask="99"
										name="certidaoNova.certificadoMatricula03"
										error={hasError(
											formik.errors,
											'certidaoNova.certificadoMatricula01',
										)}
										required
									/>
								</Col>
								<Col span={2}>
									<Field
										as={InputMask}
										mask="9999"
										name="certidaoNova.certificadoMatricula04"
										error={hasError(
											formik.errors,
											'certidaoNova.certificadoMatricula01',
										)}
										required
									/>
								</Col>
								<Col span={1}>
									<Field
										as={InputMask}
										mask="9"
										name="certidaoNova.certificadoMatricula05"
										error={hasError(
											formik.errors,
											'certidaoNova.certificadoMatricula01',
										)}
										required
									/>
								</Col>
								<Col span={2}>
									<Field
										as={InputMask}
										mask="99999"
										name="certidaoNova.certificadoMatricula06"
										error={hasError(
											formik.errors,
											'certidaoNova.certificadoMatricula01',
										)}
										required
									/>
								</Col>
								<Col span={1}>
									<Field
										as={InputMask}
										mask="999"
										name="certidaoNova.certificadoMatricula07"
										error={hasError(
											formik.errors,
											'certidaoNova.certificadoMatricula01',
										)}
										required
									/>
								</Col>
								<Col span={2}>
									<Field
										as={InputMask}
										mask="9999999"
										name="certidaoNova.certificadoMatricula08"
										error={hasError(
											formik.errors,
											'certidaoNova.certificadoMatricula01',
										)}
										required
									/>
								</Col>
								<Divider />
								<Col span={2}>
									<Field
										as={InputMask}
										mask="99"
										name="certidaoNova.certificadoMatricula09"
										error={hasError(
											formik.errors,
											'certidaoNova.certificadoMatricula01',
										)}
										required
									/>
								</Col>
							</Container>
						</Row>
					</>
				)}
				{formik.values.documentos.tipoCertidao === 'ANTIGA' && (
					<div>
						<Row gutter={[0, 10]}>
							<Col span={12}>
								<Field
									as={Input}
									title="Número"
									name="certidaoAntiga.numeroCertidao"
									error={hasError(
										formik.errors,
										'certidaoAntiga.numeroCertidao',
									)}
									maxLength={10}
									required
								/>
							</Col>
							<Col span={11} offset={1}>
								<Field
									as={Input}
									title="Folha"
									name="certidaoAntiga.numeroFolhaRegistro"
									error={hasError(
										formik.errors,
										'certidaoAntiga.numeroFolhaRegistro',
									)}
									maxLength={10}
									required
								/>
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={12}>
								<Field
									as={Input}
									title="Livro"
									name="certidaoAntiga.numeroLivroRegistro"
									error={hasError(
										formik.errors,
										'certidaoAntiga.numeroLivroRegistro',
									)}
									maxLength={35}
									required
								/>
							</Col>
							<Col span={11} offset={1}>
								<Field
									as={Input}
									setBeforeLeftPosition={22}
									title="Distrito Certidão Nascimento"
									name="certidaoAntiga.distritoNascimento"
									error={hasError(
										formik.errors,
										'certidaoAntiga.distritoNascimento',
									)}
									maxLength={10}
									required
								/>
							</Col>
						</Row>
						<Row gutter={[0, 10]}>
							<Col span={12}>
								<Field
									as={Input}
									setBeforeLeftPosition={40}
									title="Município da Comarca"
									name="certidaoAntiga.nomeMunicipioComarca"
									error={hasError(
										formik.errors,
										'certidaoAntiga.nomeMunicipioComarca',
									)}
									maxLength={35}
									required
								/>
							</Col>
							<Col span={11} offset={1}>
								<Field
									as={Select}
									title="UF da Comarca"
									name="certidaoAntiga.ufComarca"
									options={data.uf}
									onChange={(v: string) =>
										formik.setFieldValue('certidaoAntiga.ufComarca', v)
									}
									error={hasError(formik.errors, 'certidaoAntiga.ufComarca')}
									required
								/>
							</Col>
						</Row>
					</div>
				)}
			</FormBox>
		</ContainerFichaCadastral>
	);
};

export default Documentos;
