import React, { useCallback } from 'react';

import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch } from 'react-redux';
import { cdhuClear } from 'store/modules/api/cdhu/actions';

import { ROUTE_CDHU_SOLICITANTE_PESQUISAR } from 'pages/cdhu/routes/paths';

// COMPONENTS
import ButtonVoltar from 'components/Common/ButtonVoltar';

// SECTIONS
import DadosMutuario from './DadosMutuario';
import SituacaoContrato from './SituacaoContrato';
import AcordoPadrao from './AcordoPadrao';
import SimulacaoAcordo from './SimulacaoAcordo';

const Acordo: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const handleButtonVoltar = useCallback(() => {
		dispatch(cdhuClear());
		history.push(ROUTE_CDHU_SOLICITANTE_PESQUISAR);
	}, [dispatch, history]);

	return (
		<>
			<Row>
				<Col span={24}>
					<DadosMutuario />

					<SituacaoContrato />

					<AcordoPadrao />

					<SimulacaoAcordo />

					<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
				</Col>
			</Row>
		</>
	);
};

export default Acordo;
