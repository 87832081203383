import React, {
	CSSProperties,
	useCallback,
	useContext,
	useEffect,
} from 'react';
import { Col, Row } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';

import Button from 'components/Common/Button';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import SimpleTable from 'components/Common/Table';
import InputMask from 'components/Common/Form/Input/InputMask';
import TooltipInfo from 'components/Common/Tooltips/Info';
import { Title } from 'components/Common/Form/Input/styled';
import Input from 'components/Common/Form/Input/Input';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ApplicationState } from 'store';
import {
	listarSolicitacaoPortalClear,
	listarSolicitacaoPortalRequest,
} from 'store/modules/api/mp/listarSolicitacaoPortal/actions';
import { preCadastroCidadaoClear } from 'store/modules/api/sguService/preCadastroCidadao/actions';
import {
	limpaPreCadastroRequest,
	preCadastroRequest,
} from 'store/modules/api/mp/preCadastro/actions';
import { LimparSolicitacaoMP } from 'store/modules/api/mp/enviarSolicitacaoMP/actions';
import { limparAtualizaSolicitacaoPortal } from 'store/modules/api/mp/atualizarSolicitacaoPortal/actions';
import { impressaoTermoPaternidadeClear } from 'store/modules/api/mp/impressaoTermoPaternidade/actions';
import { impressaoSenhaClear } from 'store/modules/api/mp/impressaoGeraSenha/actions';
import { eventoTerminoClear } from 'store/modules/api/mp/eventoTermino/actions';
import { eventoInicioClear } from 'store/modules/api/mp/eventoInicio/actions';
import { enviarSolicitacoesPortalClear } from 'store/modules/api/mp/enviarSolicitacoesPortal/actions';

// TYPES
import { ListarSolicitacaoPortalData } from 'store/modules/api/mp/listarSolicitacaoPortal/types';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import { unformatDate } from 'utils/genericFunctions';

// CONTEXT
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// ROUTES
import { ROUTE_MINISTERIO_PUBLICO } from 'routes/paths';

// FORM
import { initialValues, schema, treatPreCadastroRequest } from './form';
import { Content, TooltipBox, Div } from './styled';

const styleBtn = {
	height: 'auto',
	display: 'block',
	padding: '5px',
	width: '195px',
	marginRight: '5px',
};

const BuscarSolicitacao: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { token } = useContext<IAuthContext>(AuthContext);
	const { listarSolicitacaoPortal } = useSelector(
		(state: ApplicationState) => state.api.ministerioPublico,
	);

	useEffect(() => {
		dispatch(enviarSolicitacoesPortalClear());
		dispatch(LimparSolicitacaoMP());
		dispatch(limparAtualizaSolicitacaoPortal());
		dispatch(limpaPreCadastroRequest());
		dispatch(impressaoTermoPaternidadeClear());
		dispatch(impressaoSenhaClear());
		dispatch(eventoInicioClear());
		dispatch(eventoTerminoClear());
		dispatch(listarSolicitacaoPortalClear());
		dispatch(preCadastroCidadaoClear());
	}, [dispatch]);

	const handleSelecionar = useCallback(
		(values: ListarSolicitacaoPortalData) => {
			const requestPreCadastro = treatPreCadastroRequest(values);
			dispatch(preCadastroRequest(requestPreCadastro));
			dispatch(listarSolicitacaoPortalClear());
			history.push(
				'/ministerio-publico/reconhecimento-de-paternidade/cadastro',
			);
		},
		[history, dispatch],
	);

	const handleRegister = useCallback(
		(solicitante: number, dados: ListarSolicitacaoPortalData) => {
			const requestPreCadastro = treatPreCadastroRequest(dados, solicitante);
			dispatch(preCadastroRequest(requestPreCadastro));
			dispatch(listarSolicitacaoPortalClear());
			history.push(
				'/ministerio-publico/reconhecimento-de-paternidade/cadastro',
			);
		},
		[dispatch, history],
	);

	const handleSubmit = useCallback(
		(cpf: string) => {
			const treatedCpf = cpf.replace(/[.-]+/g, '');
			dispatch(listarSolicitacaoPortalRequest(token, { cpf: treatedCpf }));
		},
		[dispatch, token],
	);

	const TooltipContent = (
		<>
			<p>Responsável legal, filho (a) acima de 18 anos, próprio pai.</p>
		</>
	);

	const getName = useCallback(
		(solicitante: string, dados: ListarSolicitacaoPortalData): string => {
			let name: string = '';
			if (solicitante === '1') {
				name = dados.filho.nome;
			} else if (solicitante === '3') {
				name = dados.filho.genitor.nome;
			} else if (solicitante === '2') {
				name = dados.responsavel.nome;
			}

			return name.toUpperCase();
		},
		[],
	);

	const getCpf = useCallback(
		(solicitante: string, dados: ListarSolicitacaoPortalData): string => {
			let cpf: string = '';
			if (solicitante === '1') {
				cpf = dados.filho.cpf;
			} else if (solicitante === '3') {
				cpf = dados.filho.genitor.cpf;
			} else if (solicitante === '2') {
				cpf = dados.responsavel.cpf;
			}

			return cpf.toUpperCase();
		},
		[],
	);

	const headers = [
		{
			title: 'Data Solicitação',
			dataIndex: 'dataCriacao',
			align: 'center',
			render: (dataCriacao: string) => {
				if (dataCriacao) {
					return (
						<b>
							{' '}
							{dataCriacao
								? new Date(dataCriacao).toLocaleDateString('pt-BR')
								: ''}
						</b>
					);
				}
				return '';
			},
		},
		{
			title: 'Filho (a)',
			dataIndex: 'filho',
			align: 'center',
			render: (filho: any) => {
				return <b>{filho?.nome ? filho?.nome.toUpperCase() : ''}</b>;
			},
		},
		{
			title: 'Quem Preencheu',
			dataIndex: 'responsavel',
			align: 'center',
			render: (responsavel: any, record: ListarSolicitacaoPortalData) => {
				return <b>{getName(record.destinatarioSolicitacao, record)}</b>;
			},
		},
		{
			title: 'CPF',
			dataIndex: 'responsavel',
			align: 'center',
			render: (responsavel: any, record: ListarSolicitacaoPortalData) => {
				return <b>{getCpf(record.destinatarioSolicitacao, record)}</b>;
			},
		},
		{
			title: '',
			dataIndex: 'filho',
			align: 'center',
			render: (filho: any, values: ListarSolicitacaoPortalData) => {
				if (filho?.nome) {
					return (
						<ButtonImage
							style={{ display: 'block !important' }}
							type="submit"
							src="selecionar"
							onClick={() => {
								handleSelecionar(values);
							}}
						/>
					);
				}
				return '';
			},
		},
	];

	return (
		<>
			<Section title="Reconhecimento de Paternidade">
				<Row justify="end">
					<Col span={4}>
						<ButtonImage
							type="button"
							src="novo-cadastro"
							onClick={() =>
								history.push(
									'/ministerio-publico/reconhecimento-de-paternidade/cadastro',
								)
							}
						/>
					</Col>
				</Row>
				<Section title="Buscar Solicitação" size="sm">
					<Row justify="center" gutter={[0, 16]}>
						<Col>
							<Formik
								validateOnChange={false}
								validateOnBlur={false}
								initialValues={initialValues}
								validate={values => getValidationsErrors(values, schema)}
								onSubmit={values => {
									handleSubmit(values.cpf);
								}}
							>
								{(formik: FormikProps<any>) => {
									const { errors } = formik;
									return (
										<Content>
											<Form>
												<TooltipBox>
													<Title size={30} required={false}>
														<label>CPF:</label>
													</Title>
													<TooltipInfo content={TooltipContent} />
												</TooltipBox>
												<Field
													name="cpf"
													size={200}
													as={InputMask}
													mask="999.999.999-99"
													error={hasError(errors, 'cpf')}
												/>
												<ButtonImage
													type="submit"
													src="pesquisar"
													disabled={listarSolicitacaoPortal.status === 100}
												/>
											</Form>
										</Content>
									);
								}}
							</Formik>
						</Col>
						<Col />
					</Row>
				</Section>
				<Div>
					{listarSolicitacaoPortal.data.length === 1 &&
					listarSolicitacaoPortal.data[0].id === null ? (
						<>
							<Section bgColor="cinza-claro">
								<Row align="middle" justify="center">
									<Col>
										<h2>Dados do Cidadão</h2>
									</Col>
								</Row>
								<Row justify="end">
									<Col span={12}>
										<Input
											name="nome"
											readOnly
											value={listarSolicitacaoPortal.data[0].filho.nome}
											title="Nome"
										/>
										<Input
											name="nomeMae"
											readOnly
											value={
												listarSolicitacaoPortal.data[0].filho.genitor.nomeMae
											}
											title="Nome Mãe"
										/>
										<Input name="nomePai" readOnly value="" title="Nome Pai" />
									</Col>
									<Col span={12}>
										<Input
											name="dataNascimento"
											readOnly
											value={unformatDate(
												listarSolicitacaoPortal.data[0].filho.dataNascimento,
											)}
											title="Data Nascimento"
										/>
										<Input
											name="cpf"
											readOnly
											value={listarSolicitacaoPortal.data[0].filho.cpf}
											title="CPF"
										/>
										<Input
											name="rg"
											readOnly
											value={`${listarSolicitacaoPortal.data[0].filho.rg.numero} - ${listarSolicitacaoPortal.data[0].filho.rg.digito}`}
											title="RG"
										/>
									</Col>
								</Row>
							</Section>
							<Row justify="center">
								<Col span={24} style={{ textAlign: 'center' }}>
									<h3 style={{ color: '#d90000' }}>
										Os dados do cidadão acima representa
									</h3>
								</Col>
								<Button
									style={styleBtn as CSSProperties}
									type="button"
									onClick={() =>
										handleRegister(
											1,
											listarSolicitacaoPortal
												.data[0] as ListarSolicitacaoPortalData,
										)
									}
								>
									Filho ou filha
								</Button>
								<Button
									style={styleBtn as CSSProperties}
									type="button"
									onClick={() =>
										handleRegister(
											3,
											listarSolicitacaoPortal
												.data[0] as ListarSolicitacaoPortalData,
										)
									}
								>
									Genitor
								</Button>
								<Button
									style={styleBtn as CSSProperties}
									type="button"
									onClick={() =>
										handleRegister(
											2,
											listarSolicitacaoPortal
												.data[0] as ListarSolicitacaoPortalData,
										)
									}
								>
									Responsável legal
								</Button>
							</Row>
						</>
					) : (
						<>
							<span>Resultados Encontrados:</span>
							<SimpleTable
								size="sm"
								headers={headers}
								body={listarSolicitacaoPortal.data}
								pageSize={5}
								messageNoResults="Nenhum registro encontrado"
								striped
							/>
						</>
					)}
				</Div>
			</Section>

			<ButtonVoltar route={ROUTE_MINISTERIO_PUBLICO} />
		</>
	);
};

export default BuscarSolicitacao;
