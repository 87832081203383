import React, { useCallback, useEffect, useState, useContext } from 'react';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'antd';

// REDUCER
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { recuperarAtendimentoRequest } from 'store/modules/api/sgu/atendimento/recuperarAtendimentoIntegrador/actions';

import {
	consultarBCadastroRequest,
	consultarBCadastroClear,
} from 'store/modules/api/utils/bcadastro/actions';

// COMPONENTES
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import Input from 'components/Common/Form/Input/Input';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';
import Section from 'components/Common/Section';
import { salvarAtendimentoRequest } from 'store/modules/api/sgu/atendimento/salvar/actions';
import { addNotifications } from 'store/modules/app/notifications/actions';
import { Button, QuestionTitle, Title } from './styled';
import { DataArrayProps, treatValues, treatValuesNoData } from './form';

interface Props {
	title?: string;
	route?: string;
	routeJustificativa?: string;
	onClick: () => void;
}

const RecuperaAtendimento: React.FC<Props> = ({
	title = 'Recuperar dados de atendimento',
	route,
	onClick,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const { token } = useContext<IAuthContext>(AuthContext);

	const [recoveryData, setRecoveryData] = useState<DataArrayProps | null>(null);

	const { data, messageError } = useSelector(
		(state: ApplicationState) =>
			state.api.sgu.atendimento.recuperarAtendimentoIntegrador,
	);

	const { descricaoServico } = useSelector(
		(state: ApplicationState) => state.atendimento,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { orgaos } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.user,
	);

	const { data: DataContibuinte, status: statusdoBcadastro } = useSelector(
		(state: ApplicationState) => state.api.utils.bcadastro,
	);

	useEffect(() => {
		dispatch(consultarBCadastroClear());
		dispatch(recuperarAtendimentoRequest(token, { cpf: user.cpf }));
	}, [dispatch, user.cpf, token]);

	useEffect(() => {
		// if (statusdoBcadastro === 200 || statusdoBcadastro === 204) {
		if (
			statusdoBcadastro === 0 &&
			data?.cpfCidadao !== '' &&
			data?.cpfCidadao !== undefined
		) {
			dispatch(
				consultarBCadastroRequest(token, {
					body: { cpfidentificacao: data?.cpfCidadao || '' },
					headers: { Cpfresponsavel: data?.cpfCidadao || '' },
				}),
			);
		}
	}, [dispatch, data, token, statusdoBcadastro]);

	useEffect(() => {
		if (statusdoBcadastro === 400) {
			addNotifications({
				errors: [
					'Nome do cidadão não localizado na base BDCadastros, confirme se é o cidadão que está sendo atendido através do número do CPF.',
				],
			});
		}
	}, [dispatch, data, token, statusdoBcadastro]);

	const handleOnClick = useCallback(
		(type: 'confirm' | 'cancel') => {
			if (type === 'confirm' && recoveryData) {
				const teatedValues = treatValues({
					...recoveryData,
					atendimento: data,
				});
				dispatch(salvarAtendimentoRequest(teatedValues));
				onClick();
			} else {
				history.push(`${location.pathname}/justificativa`);
			}
		},
		[data, dispatch, history, location.pathname, onClick, recoveryData],
	);

	useEffect(() => {
		const orgao = {
			id: user.idOrgao.toString(),
			nome: orgaos.find((org: any) => org.id === user.idOrgao)?.nome,
		};
		// CPF, LOCALIDADE, DESCRICAO, ORGAO
		setRecoveryData({
			cpfUsuario: user.cpf,
			codLocalidade: Number(user.posto),
			descricaoServico,
			orgao,
		});
	}, [descricaoServico, orgaos, user]);

	return (
		<Row>
			<Col span={24}>
				<Section title={title}>
					<Section bgColor="cinza-claro">
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Title>Dados do atendimento</Title>
							</Col>
						</Row>

						<Row>
							<Col span={12}>
								<Input
									name="id"
									readOnly
									title="ID Atendimento"
									value={data?.idAtendimento}
									titleAlign="start"
								/>
								<Input
									name="nome"
									readOnly
									title="Nome"
									value={DataContibuinte?.nomeContribuinte}
									titleAlign="start"
								/>
							</Col>

							<Col span={11} offset={1}>
								<Input
									name="cpf"
									readOnly
									title="CPF"
									value={data?.cpfCidadao}
									titleAlign="start"
								/>
							</Col>
						</Row>
					</Section>

					<Row gutter={[0, 20]}>
						<Col span={24} />
					</Row>

					{data?.idAtendimento ? (
						<Row gutter={[0, 10]} justify="center">
							<Col span={24}>
								<Row gutter={[0, 10]} justify="center">
									<Col>
										<QuestionTitle>Confirma dados do cidadão?</QuestionTitle>
									</Col>
								</Row>
							</Col>
							<Col span={24}>
								<Row gutter={[0, 10]} justify="center">
									<Col>
										<Button
											id="btn-confirma-dados-cidadao"
											type="button"
											color="green"
											onClick={() => handleOnClick('confirm')}
										>
											Sim
										</Button>
										<Button
											id="btn-nao-confirma-dados-cidadao"
											type="button"
											color="red"
											onClick={() => handleOnClick('cancel')}
										>
											Não
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					) : (
						<Row gutter={[0, 10]} justify="center">
							<Col>
								{route ? (
									<Link to={route}>
										<ButtonImage
											src="seguir"
											onClick={() => {
												const teatedValues = treatValuesNoData(
													recoveryData,
													messageError,
												);
												dispatch(salvarAtendimentoRequest(teatedValues));
												onClick();
											}}
										/>
									</Link>
								) : (
									<ButtonImage
										src="seguir"
										onClick={() => {
											const teatedValues = treatValuesNoData(
												recoveryData,
												messageError,
											);
											dispatch(salvarAtendimentoRequest(teatedValues));
											onClick();
										}}
									/>
								)}
							</Col>
						</Row>
					)}
				</Section>

				<ButtonVoltar />
			</Col>
		</Row>
	);
};

export default RecuperaAtendimento;
