import { Row, Col } from 'antd';
import { Form, Formik } from 'formik';
import React, {
	useCallback,
	useEffect,
	useState,
	useMemo,
	useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

// REDUX
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { useHistory } from 'react-router-dom';
import { ApplicationState } from 'store';
import { atualizarSolicitacaoPortalRequest } from 'store/modules/api/mp/atualizarSolicitacaoPortal/actions';
import {
	impressaoTermoPaternidadeClear,
	impressaoTermoPaternidadeRequest,
} from 'store/modules/api/mp/impressaoTermoPaternidade/actions';
import {
	limpaPreCadastroRequest,
	preCadastroRequest,
} from 'store/modules/api/mp/preCadastro/actions';
import { eventoInicioRequest } from 'store/modules/api/mp/eventoInicio/actions';

// CONTEXT
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// COMPONENTS
import PDFViewer from 'components/Common/PDFViewer';
import Alert from 'components/Common/Notifications/Alert';
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { enviarSolicitacoesPortalRequest } from 'store/modules/api/mp/enviarSolicitacoesPortal/actions';
import { formataSolicitaTermo, getDataEventStartSessionService } from './utils';

import { Container, Box } from './styled';
import {
	initialValues,
	schema,
	SguData,
	ICadastroSolicitante,
	treatRequestAtualizarSolicitacao,
} from './Forms/form';
import CadastroSteps from './Steps';
import Selections from './Selections';

// FORMS
import DadosInteressado from './Forms/DadosInteressado';
import DadosGenitor from './Forms/DadosGenitor';
import Termos from './Forms/Termos';
import Documentos from './Forms/UploadDocumentos';
import DadosResponsavel from './Forms/DadosResponsavel';
import Detalhes from './Forms/Detalhes';
import Confirmacao from './Confirmacao';
import {
	treatEnviarSolicitacoesPortalRequest,
	treatInitialFormValues,
} from './form';

const NovoCadastro: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { token } = useContext<IAuthContext>(AuthContext);

	const [activeStep, setActiveStep] = useState<number>(0);
	const [solicitante, setSolicitante] = useState<number>(0);
	const [descSolicitante, setDescSolicitante] = useState<string>('');
	const [formValues, setFormValues] = useState(initialValues);

	const {
		preCadastro,
		enviarSolicitacoesPortal,
		atualizarSolicitacaoPortal,
		impressaoTermoPaternidade,
		eventoInicio,
	} = useSelector((state: ApplicationState) => state.api.ministerioPublico);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { recuperarAtendimento, salvarAtendimento } = atendimento;
	const { login, user } = loginUnico;

	const sguData: SguData = useMemo(() => {
		return {
			recuperarAtendimento,
			salvarAtendimento,
			login,
			user,
		};
	}, [login, recuperarAtendimento, salvarAtendimento, user]);

	useEffect(() => {
		if (eventoInicio.status === 0) {
			const dataEventoService = getDataEventStartSessionService(sguData);
			dispatch(eventoInicioRequest(dataEventoService));
		}

		if (solicitante !== 0 && activeStep !== 2) {
			setActiveStep(1);
		} else if (solicitante === 0) {
			setActiveStep(0);
		}

		if (preCadastro?.data?.tipoSolicitante) {
			const formValuesTreated = treatInitialFormValues(preCadastro.data);

			setSolicitante(preCadastro.data.tipoSolicitante);
			setFormValues(formValuesTreated);
		}

		switch (solicitante) {
			case 1: {
				setDescSolicitante('Filho ou Filha');
				break;
			}
			case 3: {
				setDescSolicitante('Genitor');
				break;
			}
			case 2: {
				setDescSolicitante('Responsável Legal');
				break;
			}
			default:
		}

		if (
			enviarSolicitacoesPortal.status === 201 ||
			atualizarSolicitacaoPortal.status === 200 ||
			atualizarSolicitacaoPortal.status === 201
		) {
			setActiveStep(3);
		}
	}, [
		solicitante,
		enviarSolicitacoesPortal,
		preCadastro,
		atualizarSolicitacaoPortal,
		activeStep,
		sguData,
		dispatch,
		eventoInicio.status,
	]);

	const handleSubmit = useCallback(
		async (values: ICadastroSolicitante) => {
			dispatch(impressaoTermoPaternidadeClear());
			if (activeStep === 1) {
				setActiveStep(2);
				dispatch(preCadastroRequest(values));
				window.scrollTo(0, 0);
			} else if (activeStep === 2) {
				dispatch(preCadastroRequest(values));
				const body = treatEnviarSolicitacoesPortalRequest(
					values,
					solicitante,
					sguData,
				);
				if (preCadastro.data.idSolicitacao === 0) {
					dispatch(enviarSolicitacoesPortalRequest(token, body));
				} else if (preCadastro.data.idSolicitacao !== undefined) {
					const bodyAtualizaSolicitacaoPortal =
						treatRequestAtualizarSolicitacao(values, solicitante, sguData);
					dispatch(
						atualizarSolicitacaoPortalRequest(
							token,
							bodyAtualizaSolicitacaoPortal,
							preCadastro.data.idSolicitacao,
						),
					);
				}
			}
		},
		[
			dispatch,
			activeStep,
			solicitante,
			sguData,
			preCadastro.data.idSolicitacao,
			token,
		],
	);

	const handleStepPages = useCallback(() => {
		setActiveStep(activeStep - 1);
		window.scrollTo(0, 0);
		if (preCadastro.data.idSolicitacao !== 0 && activeStep === 1) {
			setSolicitante(0);
			history.push('/ministerio-publico');
		} else if (activeStep === 1) {
			dispatch(limpaPreCadastroRequest());
			setSolicitante(0);
		} else if (activeStep === 0 || activeStep === 3) {
			history.push('/ministerio-publico/reconhecimento-de-paternidade');
		} else if (activeStep === 2) {
			setActiveStep(1);
		}
	}, [dispatch, activeStep, history, preCadastro]);

	const formAlreadySentToMp: boolean = preCadastro.data.status === '2';

	return (
		<Container>
			<Formik
				enableReinitialize
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={formValues}
				validate={values =>
					getValidationsErrors(values, schema[solicitante - 1])
				}
				onSubmit={values => handleSubmit(values)}
			>
				{formik => (
					<>
						<Form autoComplete="off">
							<Section title="Cadastro">
								{formAlreadySentToMp && (
									<Alert
										type="success"
										message="Status:Enviado"
										displayOnClose={false}
									/>
								)}
								<CadastroSteps currentStep={activeStep} />
								{/* STEP 1 */}
								{solicitante === 0 && (
									<Selections formik={formik} solicitante={setSolicitante} />
								)}
								{solicitante !== 0 && activeStep !== 3 && (
									<Box>
										<strong> Solicitante: </strong>
										<span>{descSolicitante}</span>
									</Box>
								)}
								{/* STEP 2 e 3 */}
								{(activeStep === 1 || activeStep === 2) && (
									<>
										<Col span={24}>
											<DadosInteressado
												formik={formik}
												step={activeStep}
												formDisabled={formAlreadySentToMp}
											/>
											<DadosGenitor
												formik={formik}
												step={activeStep}
												formDisabled={formAlreadySentToMp}
											/>
											{(solicitante === 2 || solicitante === 3) && (
												<DadosResponsavel
													formik={formik}
													step={activeStep}
													formDisabled={formAlreadySentToMp}
												/>
											)}
											{(preCadastro.data.idSolicitacao ||
												formAlreadySentToMp) && (
												<Detalhes
													formik={formik}
													step={activeStep}
													formDisabled={formAlreadySentToMp}
												/>
											)}
											<Termos
												formik={formik}
												step={activeStep}
												formDisabled={formAlreadySentToMp}
											/>
											{!formAlreadySentToMp && (
												<Documentos
													formik={formik}
													step={activeStep}
													formDisabled={formAlreadySentToMp}
												/>
											)}
										</Col>
									</>
								)}
								{/* STEP 4 */}
								{activeStep === 3 && <Confirmacao />}
							</Section>

							{/* SUBMIT STEP 2 */}
							{solicitante !== 0 && activeStep === 1 && (
								<Row justify="center" style={{ margin: '20px 0' }}>
									<ButtonImage
										type="submit"
										src="avancar"
										disabled={formAlreadySentToMp}
									/>
								</Row>
							)}
							{/* SUBMIT STEP 3 */}
							{solicitante !== 0 && activeStep === 2 && (
								<Row
									justify="center"
									gutter={[20, 20]}
									style={{ marginTop: '20px' }}
								>
									<Col>
										<ButtonImage
											src="salvar"
											type="submit"
											disabled={formAlreadySentToMp}
										/>
									</Col>
									<Col>
										<ButtonImage
											disabled={formAlreadySentToMp}
											type="button"
											src="imprimir"
											onClick={async () => {
												const payload = await formataSolicitaTermo(
													preCadastro.data,
													login.user.nomePosto,
												);

												dispatch(impressaoTermoPaternidadeRequest(payload));
											}}
										/>
									</Col>
								</Row>
							)}
						</Form>
						<ButtonVoltar
							navigate={preCadastro.status !== 200}
							route="/ministerio-publico"
							onClick={() => {
								handleStepPages();
								formik.handleReset();
								dispatch(clearNotifications());
							}}
						/>
					</>
				)}
			</Formik>
			{impressaoTermoPaternidade.data.arquivoPDF !== '' && (
				<PDFViewer
					title="Termo de Paternidade"
					source={impressaoTermoPaternidade.data.arquivoPDF}
					renderMode="popup"
					onClose={() => dispatch(impressaoTermoPaternidadeClear())}
				/>
			)}
		</Container>
	);
};

export default NovoCadastro;
