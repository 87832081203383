import * as Yup from 'yup';

export interface Numeral {
	numeroRg: string;
	digitoRg: string;
	RGempty: boolean;
	cpf: string;
}

export const initialValues: Numeral = {
	numeroRg: '',
	digitoRg: '',
	RGempty: false,
	cpf: '',
};

export const schema = Yup.object<Numeral>().shape({
	//	numeroRg: Yup.string().required(`Campo Obrigatório: RG.`),
	validacaoRG: Yup.string().when(['numeroRg', 'cpf'], {
		is: (numeroRg, cpf) => numeroRg.length !== 8 && cpf.length < 11,
		then: Yup.string().required(`Campo Obrigatório: RG.`),
	}),
});
