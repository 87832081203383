import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { Field, Form, Formik } from 'formik';

// ROUTES
import { useHistory } from 'react-router-dom';
import {
	ROUTE_AAC_PESQUISA_ANTECEDENTES_NUMERAL,
	ROUTE_AAC_PESQUISA_CENTRAL_NUMERAL,
} from 'pages/iirgd/Aac/routes/paths';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import { limpaPesquisaCITAAAC } from 'store/modules/api/aac/pesquisaCITA/actions';
import {
	emissaoAtestadoAntecedentesRequest,
	limpaAtestadoAntecedentes,
} from 'store/modules/api/aac/emissaoAtestadoAntecedentes/actions';
import data from 'store/modules/api/iirgd/enum';

// COMPONENTS
import Section from 'components/Common/Section';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import ButtonImage from 'components/Common/ButtonImage';
import Select from 'components/Common/Form/Select';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import PDFViewer from 'components/Common/PDFViewer';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import Divider from 'components/Common/Divider';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import handleFailed from 'utils/getValidations';
import {
	convertDate,
	formatDate,
	limparMascara,
	extrairNumeroDigito,
	verificarMaioridade,
	unformatDate,
} from 'utils/genericFunctions';

// FORM
import { comparaData } from 'utils/dateFunctions';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import { RequestEmissaoAtestadoAntecedentes } from 'store/modules/api/aac/emissaoAtestadoAntecedentes/types';
import NavMenu from '../../components/NavMenu';
import { FormEmissaoNumeral, schema } from './form';

// STYLED
import { ContentButton } from '../Consultar/styled';
import Procurador from '../../components/Procurador';

const NumeralResultado: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { pesquisaCitaAAC, emissaoAtestadoAntecedentes } = useSelector(
		(state: ApplicationState) => state.api.aac,
	);

	const [protocoloBase64, setProtocoloBase64] = useState<string>('');

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { errors: notificationErros } = useSelector(
		(state: ApplicationState) => state.app.notifications,
	);

	const initialValues: FormEmissaoNumeral = {
		numeroRg: pesquisaCitaAAC.data.numeroRg,
		digitoRg: '',
		tipoDocApresentado: 'RG',
		dataExpedicaoRg: '',
		nomeSocial: pesquisaCitaAAC.data.nomeSocial,
		nome: pesquisaCitaAAC.data.nome,
		nomePai: pesquisaCitaAAC.data.nomePai,
		nomeMae: pesquisaCitaAAC.data.nomeMae,
		sexo: pesquisaCitaAAC.data.sexo,
		dataNascimento: formatDate(pesquisaCitaAAC.data.dataNascimento),
		requerente: false,
		nomeProcurador: '',
		docProcurador: '',
		posto: login.user.posto,
		ipEstacao: login.user.ip,
		usuario: login.mainframe.idUsuario,
		numeroDocumento: '',
		origem: 'BU',
		tipoEmissao: 'NUMERAL',
	};

	const [dataExpedicao, setDataExpedicao] = useState<string>('');
	const [dataInvalida, setDataInvalida] = useState<boolean>(true);

	useEffect(() => {
		if (dataInvalida && limparMascara(dataExpedicao).length === 8) {
			addNotifications({
				errors: [
					'A data de expedição não pode ser anterior a data de nascimento.',
				],
			});
		}
	}, [dataExpedicao, dataInvalida]);

	const validaDataExpedicao = useCallback(
		(values: string): void => {
			if (limparMascara(values).length < 8) {
				return setDataInvalida(true);
			}
			if (
				pesquisaCitaAAC.data.dataNascimento &&
				values &&
				limparMascara(values).length === 8
			) {
				if (
					!comparaData(
						values,
						pesquisaCitaAAC.data.dataNascimento,
						'maiorIgual',
					)
				) {
					return setDataInvalida(
						!comparaData(
							values,
							pesquisaCitaAAC.data.dataNascimento,
							'maiorIgual',
						),
					);
				}
				dispatch(clearNotifications());

				return setDataInvalida(
					!comparaData(
						values,
						pesquisaCitaAAC.data.dataNascimento,
						'maiorIgual',
					),
				);
			}

			return setDataInvalida(
				!comparaData(values, pesquisaCitaAAC.data.dataNascimento, 'maiorIgual'),
			);
		},
		[dispatch, pesquisaCitaAAC.data.dataNascimento],
	);

	useEffect(() => {
		validaDataExpedicao(dataExpedicao);
	}, [dataExpedicao, validaDataExpedicao]);

	const handleSubmitForm = useCallback(
		(values: FormEmissaoNumeral) => {
			if (values.dataNascimento) {
				if (!verificarMaioridade(values.dataNascimento)) {
					addNotifications({
						errors: [
							'Atenção, a emissão de atestado só é permitida para maiores de 18 anos!',
						],
					});
				} else {
					const formData: RequestEmissaoAtestadoAntecedentes = {
						dataNascimento: null,
						dataNascimentoTs: `${unformatDate(values.dataNascimento)} 12:00:00`,
						dataExpedicaoRg: convertDate(values.dataExpedicaoRg),
						nomeSocial: values.nomeSocial,
						nome: values.nome,
						nomeMae: values.nomeMae === 'NC' ? '' : values.nomeMae,
						nomePai: values.nomePai === 'NC' ? '' : values.nomePai,
						numeroDocumento: '',
						numeroRg:
							values.tipoDocApresentado.toLocaleUpperCase() === 'RG'
								? extrairNumeroDigito(values.numeroRg, 'num')
								: '',
						digitoRg:
							values.tipoDocApresentado.toLocaleUpperCase() === 'RG'
								? extrairNumeroDigito(values.numeroRg, 'dig')
								: '',
						sexo: values.sexo,
						ipEstacao: login.user.ip,
						posto: login.user.posto,
						tipoDocApresentado: values.tipoDocApresentado,
						usuario: login.mainframe.idUsuario,
						nomeProcurador: values.nomeProcurador.toUpperCase() ?? '',
						docProcurador: values.docProcurador.toUpperCase() ?? '',
						tipoEmissao: 'NUMERAL',
						origem: 'BU',
						cpf:
							values.tipoDocApresentado.toLocaleUpperCase() === 'CIN' &&
							pesquisaCitaAAC.data.cpf
								? `${extrairNumeroDigito(pesquisaCitaAAC.data.cpf, 'num')}`
								: '',
						dcCpf:
							values.tipoDocApresentado.toLocaleUpperCase() === 'CIN' &&
							pesquisaCitaAAC.data.cpf
								? `${extrairNumeroDigito(pesquisaCitaAAC.data.cpf, 'dig')}`
								: '',
					};
					dispatch(emissaoAtestadoAntecedentesRequest(formData));
				}
			}
		},
		[
			dispatch,
			login.mainframe.idUsuario,
			login.user.ip,
			login.user.posto,
			pesquisaCitaAAC.data.cpf,
		],
	);

	useEffect(() => {
		if (
			emissaoAtestadoAntecedentes.status !== 200 &&
			emissaoAtestadoAntecedentes.data.mensagem
		) {
			handleFailed({
				mensagem_erro: emissaoAtestadoAntecedentes.data.mensagem,
			});
		} else if (
			emissaoAtestadoAntecedentes.status === 200 &&
			emissaoAtestadoAntecedentes.data.pdfBase64 &&
			emissaoAtestadoAntecedentes.data.pdfBase64 !== ''
		) {
			setProtocoloBase64(emissaoAtestadoAntecedentes.data.pdfBase64);
			dispatch(limpaAtestadoAntecedentes());
		} else if (
			emissaoAtestadoAntecedentes.status === 200
			// &&			emissaoAtestadoAntecedentes.data.codigo === 'CO1' &&
			// emissaoAtestadoAntecedentes.data.nomeSocial !== ''
		) {
			history.push(ROUTE_AAC_PESQUISA_CENTRAL_NUMERAL);
		} else if (pesquisaCitaAAC.status === 0) {
			history.push(ROUTE_AAC_PESQUISA_ANTECEDENTES_NUMERAL);
		}
	}, [emissaoAtestadoAntecedentes, history, dispatch, pesquisaCitaAAC]);

	return (
		<>
			<Row>
				<Col span={24}>
					<NavMenu />

					<Divider mtSize={15} mbSize={15} />
				</Col>
			</Row>
			<Section title="Resultado ">
				<Formik
					validateOnChange={!!notificationErros.length}
					validateOnBlur={!!notificationErros.length}
					validate={values => getValidationsErrors(values, schema)}
					initialValues={initialValues}
					onSubmit={handleSubmitForm}
				>
					{formik => {
						const { errors, setFieldValue } = formik;
						return (
							<Form autoComplete="off">
								<Row gutter={[0, 10]}>
									<Col span={8}>
										<Field
											name="numeroRg"
											title="RG de SP"
											type="text"
											mask="99.999.999-**"
											maskChar=""
											disabled
											as={InputMask}
										/>
									</Col>
									<Col span={6}>
										<Field
											as={Select}
											name="tipoDocApresentado"
											type="text"
											title="Doc. Apresentado"
											onChange={(v: number) =>
												setFieldValue('tipoDocApresentado', v)
											}
											options={data.docApresentado}
										/>
									</Col>
									<Col span={9} offset={1}>
										<Field
											id="dataExpedicaoRg"
											title="Data de Expedição"
											name="dataExpedicaoRg"
											type="text"
											mask="99/99/9999"
											subtitle="(DD/MM/AAAA)"
											size={40}
											as={ValidDataInput}
											error={hasError(errors, 'dataExpedicaoRg')}
											formik={formik}
											customChanges={(
												v: React.ChangeEvent<HTMLInputElement>,
											) => {
												setDataExpedicao(v.currentTarget.value);
												setFieldValue('dataExpedicaoRg', v.currentTarget.value);
											}}
										/>
									</Col>
									<Col span={24}>
										<Field
											title="Nome"
											name="nome"
											size={80}
											as={Input}
											disabled
										/>
									</Col>
									<Col span={24}>
										<Field
											title="Nome Pai"
											name="nomePai"
											size={80}
											as={Input}
											disabled
										/>
									</Col>
									<Col span={24}>
										<Field
											title="Nome Mãe"
											name="nomeMae"
											size={80}
											as={Input}
											disabled
										/>
									</Col>
									<Col span={8}>
										<Field
											as={Select}
											name="sexo"
											type="text"
											title="Sexo"
											size={85}
											options={data.sexo}
											disabled
										/>
									</Col>
									<Col span={16}>
										<Field
											title="Data de Nascimento"
											name="dataNascimento"
											type="text"
											mask="99/99/9999"
											subtitle="(DD/MM/AAAA)"
											size={25}
											as={InputMask}
											disabled
										/>
									</Col>
									<Procurador formik={formik} />
								</Row>
								{!dataInvalida && (
									<ContentButton justify="start">
										<ButtonImage type="submit" src="imprimir" />
									</ContentButton>
								)}
							</Form>
						);
					}}
				</Formik>
			</Section>
			{protocoloBase64 && (
				<PDFViewer
					title="Atestado de antecedentes"
					source={protocoloBase64}
					onClose={() => setProtocoloBase64('')}
				/>
			)}
			<ButtonVoltar
				route="/iirgd/pesquisa-antecedentes/numeral/pesquisa-antecedentes"
				onClick={() => dispatch(limpaPesquisaCITAAAC())}
			/>
		</>
	);
};

export default NumeralResultado;
