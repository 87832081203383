import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

// REDUX
import { store } from 'store';
import { setLoader } from 'store/modules/app/loader/actions';
import { clearNotificationsValidate } from 'store/modules/app/notifications/actions';

// UTILS
import handleFailed, { handleFailedBody } from 'utils/getValidations';
import { handleTempoInatividade } from '../utils';
import {
	verifyServiceIntegradorUseBffToGenerateLog,
	treatPathBaseBffUrl,
	treatPathBaseIntegradorUrl,
} from './utils/paths/treatPaths';

export interface ApiResponse {
	status: number;
	data?: any;
	municipios?: any;
	error?: boolean;
	message?: any;
	numRg?: string;
}

export interface ResponseError {
	error: boolean;
	message: string;
}

export interface ResponseCheckTimeToken {
	status: number;
	error: boolean;
	message: string;
}

export interface ApiConfig {
	method: string;
	url: string;
	params?: object;
	data?: any;
	headers: ApiConfigHeaders;
	validateStatus?: ((status: number) => boolean) | null;
}

export interface ApiConfigHeaders {
	Authorization: string | ResponseError;
	header?: object;
}

async function resultResponse(
	config: AxiosRequestConfig,
	service: string,
): Promise<AxiosResponse<any>> {
	let treatedBaseUrl = '';
	const configUpdated = { ...config };

	if (verifyServiceIntegradorUseBffToGenerateLog(config)) {
		const { serviceBff, pathBase } = treatPathBaseBffUrl(config);
		treatedBaseUrl = pathBase;
		configUpdated.url = serviceBff;
	} else {
		treatedBaseUrl = treatPathBaseIntegradorUrl(service);
	}

	const api = axios.create({
		baseURL: treatedBaseUrl,
	});

	const response = await api(configUpdated);

	return response;
}

function runSetLoader(method: string) {
	const methodURL = method.split('/') || [];
	const methodURLLength = methodURL ? methodURL.length - 1 : 0;
	const lastCall = methodURL.length ? methodURL[methodURLLength] : '';

	const storageValue = localStorage.getItem('lastCallApi');
	const storageValueResult: string[] = storageValue
		? JSON.parse(storageValue)
		: [];

	const incrementStorage = [...storageValueResult, lastCall];
	localStorage.setItem('lastCallApi', JSON.stringify(incrementStorage));
	clearNotificationsValidate();
	setLoader(true);
}

function stopSetLoader(method: string) {
	const methodURL = method.split('/') || [];
	const methodURLLength = methodURL ? methodURL.length - 1 : 0;
	const lastCall = methodURL.length ? methodURL[methodURLLength] : '';

	const storageValue = localStorage.getItem('lastCallApi');
	const storageValueResult: string[] = storageValue
		? JSON.parse(storageValue)
		: [];
	const arrayDiference =
		storageValueResult.filter(value => value !== lastCall) || [];
	if (arrayDiference.length) {
		localStorage.setItem('lastCallApi', JSON.stringify(arrayDiference));
	} else {
		localStorage.setItem('lastCallApi', JSON.stringify([]));
	}

	if (storageValueResult.length <= 1 && arrayDiference.length === 0) {
		localStorage.removeItem('lastCallApi');
		setLoader(false);
	}
}

// Caso seja decidido usar o token com o valor do localStorage
// const getToken = () => {
// 	const tokenStorage =
// 		localStorage.getItem('ROCP_token')?.replaceAll('"', '') || '';
// 	const token = `Bearer ${tokenStorage}`;
// 	return token;
// };

export async function getApiArchives(
	token: string,
	service: string,
	method: string,
	params?: object,
	defaultErrorMessage?: string,
	semNotificacao?: boolean,
	contadorChamadaToken?: number,
	header?: object,
	statusToValidate?: number[],
): Promise<ApiResponse> {
	runSetLoader(method);
	const state = store.getState();
	await handleTempoInatividade(state.api.sgu.loginUnico.tempoLogado);

	const authorization = `Bearer ${token || ''}`;

	const config: AxiosRequestConfig = {
		method: 'get',
		url: `/${method}`,
		params,
		headers: {
			Authorization: authorization,
			...header,
		},
		responseType: 'blob',
		data: {},
		validateStatus: status => {
			return (
				(statusToValidate && statusToValidate.includes(status)) ||
				status === 200
			);
		},
	};
	let contador = contadorChamadaToken ?? 0;

	try {
		const result = await resultResponse(config, service);
		return result;
	} catch (error) {
		if (error?.response?.status === 401 && contador < 2) {
			try {
				contador++;
				config.headers.Authorization = authorization;
				const result = await getApiArchives(
					token,
					service,
					method,
					params,
					defaultErrorMessage,
					semNotificacao,
					contador,
				);

				return result;
			} catch (errorResult) {
				return {
					status: 0,
					error: true,
					message: 'Sistema fora do ar!!!',
				};
			}
		}

		const errorData = (await handleFailedBody(error)) as any;
		const errorResponse = await handleFailed(
			error,
			!!semNotificacao,
			defaultErrorMessage,
			true,
		);

		return {
			status: 0,
			error: true,
			message: errorResponse,
			data: errorData.data,
		};
	} finally {
		setTimeout(() => {
			stopSetLoader(method);
		}, 1000);
	}
}

export async function getApi(
	token: string,
	service: string,
	method: string,
	params?: object,
	defaultErrorMessage?: string,
	semNotificacao?: boolean,
	contadorChamadaToken?: number,
	header?: object,
	statusToValidate?: number[],
): Promise<ApiResponse> {
	runSetLoader(method);
	const state = store.getState();
	await handleTempoInatividade(state.api.sgu.loginUnico.tempoLogado);

	const authorization = `Bearer ${token || ''}`;

	const config: AxiosRequestConfig = {
		method: 'get',
		url: `/${method}`,
		params,
		headers: {
			Authorization: authorization,
			...header,
		},
		validateStatus: status => {
			return (
				(statusToValidate && statusToValidate.includes(status)) ||
				status === 200
			);
		},
	};
	let contador = contadorChamadaToken ?? 0;

	try {
		const result = await resultResponse(config, service);
		return result;
	} catch (error) {
		if (error?.response?.status === 401 && contador < 2) {
			try {
				contador++;
				config.headers.Authorization = authorization;
				const result = await getApi(
					token,
					service,
					method,
					params,
					defaultErrorMessage,
					semNotificacao,
					contador,
				);

				return result;
			} catch (errorResult) {
				return {
					status: 0,
					error: true,
					message: 'Sistema fora do ar!!!',
				};
			}
		}

		const errorData = (await handleFailedBody(error)) as any;
		const errorResponse = await handleFailed(
			error,
			!!semNotificacao,
			defaultErrorMessage,
			true,
		);

		return {
			status: 0,
			error: true,
			message: errorResponse,
			data: errorData.data,
		};
	} finally {
		setTimeout(() => {
			stopSetLoader(method);
		}, 1000);
	}
}

export async function postApi(
	token: string,
	service: string,
	method: string,
	body: any,
	header?: object,
	semNotificacao?: boolean,
	contadorChamadaToken?: number,
): Promise<ApiResponse> {
	const state = store.getState();

	await handleTempoInatividade(state.api.sgu.loginUnico.tempoLogado);

	runSetLoader(method);

	let contador = contadorChamadaToken ?? 0;

	const authorization = `Bearer ${token}`;

	const config: AxiosRequestConfig = {
		method: 'post',
		url: `/${method}`,
		data: body,
		headers: {
			Authorization: authorization,
			...header,
		},
	};

	try {
		const result = await resultResponse(config, service);
		return result;
	} catch (error) {
		if (error?.response?.status === 401 && contador < 2) {
			try {
				contador++;
				config.headers.Authorization = authorization;
				const result = await postApi(
					token,
					service,
					method,
					body,
					header,
					semNotificacao,
					contador,
				);

				return result;
			} catch (errorResult) {
				return {
					status: 0,
					error: true,
					message: 'Sistema fora do ar!!!',
				};
			}
		}

		const errorResponse = await handleFailed(error, !!semNotificacao);
		const errorData = await handleFailedBody(error);

		const message =
			errorResponse.length > 1 ? [errorResponse[0]] : errorResponse;

		return {
			status: 0,
			data: errorData,
			error: true,
			message,
			numRg: errorResponse[1] ?? '',
		};
	} finally {
		// setTimeout(() => {
		stopSetLoader(method);

		// }, 1000);
	}
}

export async function putApi(
	token: string,
	service: string,
	method: string,
	body: any,
	header?: object,
	contadorChamadaToken?: number,
): Promise<ApiResponse> {
	runSetLoader(method);
	const state = store.getState();
	await handleTempoInatividade(state.api.sgu.loginUnico.tempoLogado);

	let contador = contadorChamadaToken ?? 0;

	const authorization = `Bearer ${token}`;

	const config: AxiosRequestConfig = {
		method: 'put',
		url: `/${method}`,
		data: body,
		headers: {
			Authorization: authorization,
			...header,
		},
	};

	try {
		const result = await resultResponse(config, service);

		if (result.status === 200 && result.data.mensagem_erro) {
			const errorResponse = await handleFailed(result.data.mensagem_erro);
			return { status: 0, error: true, message: errorResponse };
		}

		return result;
	} catch (error) {
		if (error?.response?.status === 401 && contador < 2) {
			try {
				contador++;
				config.headers.Authorization = authorization;
				const result = await putApi(token, service, method, body, header);

				return result;
			} catch (errorResult) {
				return {
					status: 0,
					error: true,
					message: 'Sistema fora do ar!!!',
				};
			}
		}

		const errorResponse = await handleFailed(error);
		return { status: 0, error: true, message: errorResponse };
	} finally {
		setTimeout(() => {
			stopSetLoader(method);
		}, 1000);
	}
}

export async function patchApi(
	token: string,
	service: string,
	method: string,
	body: any,
	header?: object,
	contadorChamadaToken?: number,
): Promise<ApiResponse> {
	runSetLoader(method);
	const state = store.getState();
	await handleTempoInatividade(state.api.sgu.loginUnico.tempoLogado);

	let contador = contadorChamadaToken ?? 0;

	const authorization = `Bearer ${token}`;

	const config: AxiosRequestConfig = {
		method: 'patch',
		url: `/${method}`,
		data: body,
		headers: {
			Authorization: token,
			...header,
		},
	};

	try {
		const result = await resultResponse(config, service);

		if (result.status === 200 && result.data.mensagem_erro) {
			const errorResponse = await handleFailed(result.data.mensagem_erro);
			return { status: 0, error: true, message: errorResponse };
		}

		return result;
	} catch (error) {
		if (error?.response?.status === 401 && contador < 2) {
			try {
				contador++;
				config.headers.Authorization = authorization;
				const result = await putApi(token, service, method, body, header);

				return result;
			} catch (errorResult) {
				return {
					status: 0,
					error: true,
					message: 'Sistema fora do ar!!!',
				};
			}
		}

		const errorResponse = await handleFailed(error);
		return { status: 0, error: true, message: errorResponse };
	} finally {
		setTimeout(() => {
			stopSetLoader(method);
		}, 1000);
	}
}

export async function deleteApi(
	token: string,
	service: string,
	method: string,
	params?: object,
	contadorChamadaToken?: number,
): Promise<ApiResponse> {
	runSetLoader(method);
	const state = store.getState();
	await handleTempoInatividade(state.api.sgu.loginUnico.tempoLogado);

	let contador = contadorChamadaToken ?? 0;

	const authorization = `Bearer ${token}`;

	const config: AxiosRequestConfig = {
		method: 'delete',
		url: `/${method}`,
		data: params,
		headers: {
			Authorization: authorization,
		},
	};

	try {
		const result = await resultResponse(config, service);

		if (result.status === 200 && result.data.mensagem_erro) {
			const errorResponse = await handleFailed(result.data.mensagem_erro);
			return { status: 0, error: true, message: errorResponse };
		}

		return result;
	} catch (error) {
		if (error?.response?.status === 401 && contador < 2) {
			try {
				contador++;
				config.headers.Authorization = authorization;
				const result = await deleteApi(token, service, method, params);

				return result;
			} catch (errorResult) {
				return {
					status: 0,
					error: true,
					message: 'Sistema fora do ar!!!',
				};
			}
		}

		const errorResponse = await handleFailed(error);
		return { status: 0, error: true, message: errorResponse };
	} finally {
		setTimeout(() => {
			stopSetLoader(method);
		}, 1000);
	}
}
