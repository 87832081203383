// TYPES
import { ReducerAction } from 'store/modules/types';
import { ConsultaCpfAac, Types } from './types';

export function consultaCpfAacRequest(payload: string): ReducerAction {
	return {
		type: Types.CONSULTA_CPF_AAC_REQUEST,
		payload,
	};
}

export function consultaCpfAacSuccess(payload: ConsultaCpfAac): ReducerAction {
	return {
		type: Types.CONSULTA_CPF_AAC_SUCCESS,
		payload,
	};
}

export function consultaCpfAacFailure(): ReducerAction {
	return {
		type: Types.CONSULTA_CPF_AAC_FAILURE,
		payload: null,
	};
}

export function limpaConsultaNominal(): ReducerAction {
	return {
		type: Types.CONSULTA_CPF_AAC_CLEAR,
		payload: null,
	};
}
