import React, {
	Suspense,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import { Skeleton } from 'antd';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	consultarVeiculoV110Clear,
	consultarVeiculoV110Request,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';
import {
	consultarVeiculoV120Clear,
	consultarVeiculoV120Request,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV120/actions';
import {
	consultarRestricaoVeiculoRequest,
	consultarRestricaoVeiculoClear,
} from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarRestricaoVeiculo/actions';
import { IRequestConsultaRestricaoVeiculo } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarRestricaoVeiculo/types';
import {
	consultarTaxaServicoClear,
	consultarTaxaServicoRequest,
} from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarTaxaServico/actions';
import { ConsultarTaxaServicoRequest } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarTaxaServico/types';

// COMPONENTS
import Section from 'components/Common/Section';
import Error from 'components/Common/Notifications/FormError/index';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// ROUTES
import { ROUTE_DETRAN_CRV } from 'routes/paths';
import { ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_ESTADO_CONFIRMACAO } from 'pages/DetranCrv/routes/paths';

// FORM
import { Formik, FormikProps } from 'formik';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { errorCodesAllowedsConsultarRestricaoVeiculo } from 'pages/DetranCrv/utils/constants/servicesResponse';
import PesquisaPadraoCrv from 'pages/DetranCrv/components/pages/PesquisaPadraoCrv';
import {
	estatisticaIncluirRequest,
	estatisticaIncluirClear,
} from 'store/modules/api/sguService/estatisticasIncluir/actions';
import {
	initialValues,
	ITransferenciaMunicipioEstado,
	schema,
	treatRequestSguStatistics,
} from './form';

const TransferenciaMunicipioEstado: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { token } = useContext<IAuthContext>(AuthContext);

	const [bodyRestricao, setBodyRestricao] =
		useState<IRequestConsultaRestricaoVeiculo>({
			placa: '',
			documento: '',
			espelho: '',
			renavam: '',
			segundaViaCrv: '',
			uf: '',
		});

	const [notificationErrors, setNotificationErrors] = useState<string[]>([]);
	const [categoriaOficial, setCategoriaOficial] = useState<string | number>('');

	const { consultarVeiculoV110, consultarVeiculoV120 } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const { consultarRestricaoVeiculo, consultarTaxaServico } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarRestricoes,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const [formData, setFormData] = useState(initialValues);

	useEffect(() => {
		if (
			consultarVeiculoV110.status === 200 &&
			consultarVeiculoV110.data &&
			consultarTaxaServico.status === 0 &&
			categoriaOficial !== 3
		) {
			const codigoTaxaServicoData: boolean =
				consultarVeiculoV110.data.caracteristicaVeiculo.ufPlacaAnterior ===
				'SP';

			const dataConsultarTaxaServico: ConsultarTaxaServicoRequest = {
				codigoTaxaServico: codigoTaxaServicoData ? '4' : '5',
				identificadorTaxa: codigoTaxaServicoData
					? consultarVeiculoV110.data.caracteristicaVeiculo.renavam.toString() ||
					  ''
					: formData.documento || '',
				despachante: '',
			};

			dispatch(consultarTaxaServicoRequest(dataConsultarTaxaServico));
		}
	}, [
		formData,
		dispatch,
		consultarTaxaServico.status,
		consultarVeiculoV110.status,
		consultarVeiculoV110.data,
		categoriaOficial,
	]);

	useEffect(() => {
		if (
			consultarVeiculoV120.status === 200 &&
			consultarVeiculoV120.data &&
			consultarTaxaServico.status === 0 &&
			categoriaOficial !== 3
		) {
			const codigoTaxaServicoData: boolean =
				consultarVeiculoV120.data.caracteristicaVeiculo.ufPlacaAnterior ===
				'SP';

			const dataConsultarTaxaServico: ConsultarTaxaServicoRequest = {
				codigoTaxaServico: codigoTaxaServicoData ? '4' : '5',
				identificadorTaxa: codigoTaxaServicoData
					? consultarVeiculoV120.data.caracteristicaVeiculo.renavam.toString() ||
					  ''
					: formData.documento || '',
				despachante: '',
			};

			dispatch(consultarTaxaServicoRequest(dataConsultarTaxaServico));
		}
	}, [
		consultarVeiculoV120.status,
		formData,
		dispatch,
		consultarTaxaServico.status,
		consultarVeiculoV120.data,
		categoriaOficial,
	]);

	useEffect(() => {
		dispatch(consultarRestricaoVeiculoClear());
		let bodyRestricaoReq: IRequestConsultaRestricaoVeiculo = {
			placa: '',
			documento: '',
			espelho: '',
			renavam: '',
			segundaViaCrv: '',
			uf: '',
		};

		if (consultarVeiculoV110.status !== 200) {
			if (consultarVeiculoV110.message && consultarVeiculoV110.message !== '') {
				const codigo = consultarVeiculoV110.message.split(' - ')[0];

				if (codigo === 'A0132' || codigo === 'A0136') {
					const body = {
						placa: formData.placa,
						chassi: formData.chassi,
						tipoConsulta: formData.tipoConsulta,
					};

					dispatch(consultarVeiculoV120Request(body));
					dispatch(consultarVeiculoV110Clear());
				}
			}
		}

		if (
			consultarVeiculoV110.status === 200 &&
			(consultarTaxaServico.status === 200 || categoriaOficial === 3)
		) {
			if (
				formData.restricaoFinanceira !== '' &&
				formData.restricaoFinanceira !==
					consultarVeiculoV110.data?.caracteristicaVeiculo.gravame.restricao
			) {
				setNotificationErrors([
					'ERRO NA CONSISTÊNCIA DE GRAVAMES PARA ESTE VEÍCULO. FAVOR VERIFICAR.',
				]);
			} else {
				bodyRestricaoReq = {
					placa: consultarVeiculoV110?.data?.caracteristicaVeiculo?.placa || '',
					documento:
						formData?.documento ||
						consultarVeiculoV110?.data?.caracteristicaVeiculo?.proprietario?.cpfCnpjProprietario?.toString() ||
						'',
					espelho: formData?.espelho || '',
					renavam:
						consultarVeiculoV110.data?.caracteristicaVeiculo?.renavam?.toString() ||
						'',
					uf:
						consultarVeiculoV110.data?.caracteristicaVeiculo?.ufPlacaAnterior ||
						'SP',
				};
				if (formData.documento) {
					setBodyRestricao(bodyRestricaoReq);
					dispatch(
						consultarRestricaoVeiculoRequest(
							bodyRestricaoReq as IRequestConsultaRestricaoVeiculo,
							token,
						),
					);
				}
			}
		}
	}, [
		consultarVeiculoV110,
		dispatch,
		history,
		formData,
		consultarTaxaServico.status,
		categoriaOficial,
		token,
	]);

	useEffect(() => {
		let bodyRestricaoReq: IRequestConsultaRestricaoVeiculo = {
			placa: '',
			documento: '',
			espelho: '',
			renavam: '',
			segundaViaCrv: '',
			uf: '',
		};
		if (
			consultarVeiculoV120.status === 200 &&
			(consultarTaxaServico.status === 200 || categoriaOficial === 3)
		) {
			if (
				formData.restricaoFinanceira !== '' &&
				formData.restricaoFinanceira !==
					consultarVeiculoV120.data?.caracteristicaVeiculo.gravame.restricao
			) {
				setNotificationErrors([
					'ERRO NA CONSISTÊNCIA DE GRAVAMES PARA ESTE VEÍCULO. FAVOR VERIFICAR.',
				]);
			} else {
				bodyRestricaoReq = {
					placa: consultarVeiculoV120.data?.caracteristicaVeiculo
						.placa as string,
					documento:
						formData.documento ||
						(consultarVeiculoV120.data?.caracteristicaVeiculo.dadosProprietario.cpfCnpjProprietario.toString() as string),
					espelho: formData.espelho,
					renavam:
						consultarVeiculoV120.data?.caracteristicaVeiculo?.renavam.toString() ||
						'',
					uf:
						consultarVeiculoV120.data?.caracteristicaVeiculo.ufPlacaAnterior ||
						'SP',
				};
				if (formData.documento && consultarRestricaoVeiculo.status === 0) {
					setBodyRestricao(bodyRestricaoReq);
					dispatch(
						consultarRestricaoVeiculoRequest(
							bodyRestricaoReq as IRequestConsultaRestricaoVeiculo,
							token,
						),
					);
				}
			}
		}
	}, [
		consultarVeiculoV120,
		dispatch,
		consultarTaxaServico.status,
		categoriaOficial,
		consultarRestricaoVeiculo.status,
		formData,
		token,
	]);

	useEffect(() => {
		if (
			(consultarVeiculoV120.status === 200 ||
				consultarVeiculoV110.status === 200) &&
			(consultarTaxaServico.status === 200 || categoriaOficial === 3)
		) {
			if (
				consultarRestricaoVeiculo.status === 200 ||
				(consultarRestricaoVeiculo.status ===
					errorCodesAllowedsConsultarRestricaoVeiculo.code199.statusResponse &&
					consultarRestricaoVeiculo.dataError &&
					Number(consultarRestricaoVeiculo.dataError.codigo) ===
						errorCodesAllowedsConsultarRestricaoVeiculo.code199.code) ||
				(categoriaOficial === 3 &&
					consultarRestricaoVeiculo.status ===
						errorCodesAllowedsConsultarRestricaoVeiculo.code463
							.statusResponse &&
					consultarRestricaoVeiculo.dataError &&
					Number(consultarRestricaoVeiculo.dataError.codigo) &&
					errorCodesAllowedsConsultarRestricaoVeiculo.code463.code)
			) {
				dispatch(clearNotifications());

				const payload = treatRequestSguStatistics(
					user,
					formData,
					consultarVeiculoV110.data,
					consultarVeiculoV120.data,
				);

				dispatch(estatisticaIncluirRequest(payload));

				history.push({
					pathname: ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_ESTADO_CONFIRMACAO,
					state: {
						espelho: bodyRestricao.espelho,
						cpf: bodyRestricao.documento || '',
					},
				});
			}
		}
	}, [
		dispatch,
		history,
		formData,
		bodyRestricao,
		consultarRestricaoVeiculo.dataError,
		consultarRestricaoVeiculo.status,
		consultarTaxaServico.status,
		consultarVeiculoV110,
		user,
		consultarVeiculoV120,
		categoriaOficial,
	]);

	const handleClear = useCallback(
		(formik?: FormikProps<ITransferenciaMunicipioEstado>) => {
			if (formik) {
				formik.handleReset();
			}
			dispatch(clearNotifications());
			setNotificationErrors([]);
			dispatch(consultarVeiculoV110Clear());
			dispatch(consultarVeiculoV120Clear());
			dispatch(consultarRestricaoVeiculoClear());
			dispatch(consultarTaxaServicoClear());
			dispatch(estatisticaIncluirClear());
		},
		[dispatch],
	);

	const handleSubmit = useCallback(
		(values: ITransferenciaMunicipioEstado) => {
			handleClear();

			// if (consultarTaxaServico.status === 200) {
			setFormData(values);
			const body = {
				placa: values.placa,
				chassi: values.chassi,
				tipoConsulta: values.tipoConsulta,
				restricaoFinanceira: values.restricaoFinanceira,
			};
			dispatch(consultarVeiculoV110Request(body));
			// }
		},
		[dispatch, handleClear],
	);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			{notificationErrors.length > 0 && (
				<Error
					errors={notificationErrors}
					onClose={() => setNotificationErrors([])}
				/>
			)}
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={handleSubmit}
				>
					{(formik: FormikProps<any>) => {
						return (
							<PesquisaPadraoCrv
								formik={formik}
								setNotificationErrors={setNotificationErrors}
								notificationErrors={notificationErrors}
								setCategoriaOficial={setCategoriaOficial}
							/>
						);
					}}
				</Formik>
			</Section>
			<ButtonVoltar route={ROUTE_DETRAN_CRV} />
		</Suspense>
	);
};

export default TransferenciaMunicipioEstado;
