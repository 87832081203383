import * as Yup from 'yup';
import { v4 as uuidV4 } from 'uuid';

import { onlyLetters, minCharacters } from 'utils/yupValidations';
import {
	convertDate,
	formatDateISOWithOutUTC,
	limparMascara,
} from 'utils/genericFunctions';
import {
	DeficienciaCadastroFichaAluno,
	FichaAlunoEnvio,
	RecursoAvaliacaoCadastroFichaAluno,
} from 'store/modules/api/educacaoUpdate/fichaAluno/cadastrarFichaAluno/types';
import {
	AlterarFichaAlunoRequestData,
	AlterarFichaAlunoTelefone,
} from 'store/modules/api/educacaoUpdate/fichaAluno/alterarFichaAluno/types';
import {
	ConsultaFichaAlunoData,
	Irmao,
	NecessidadesEspeciais,
	Telefone,
} from 'store/modules/api/educacaoUpdate/fichaAluno/consultaFichaAluno/types';

export interface IFichaAluno {
	aluno: Aluno;
	idProcesso: number;
	mensagem: string;
	dataAlteracao: string;
	dadosPessoais: IDadosPessoais;
	documentos: IDocumentos;
	justificativaDocumentos?: string;
	certidaoAntiga: ICertidaoAntiga;
	certidaoNova: ICertidaoNova;
	enderecoResidencial: IEnderecoResidencial;
	telefones: ITelefone[];
	telefone: ITelefone;
	deficiencia: IDeficiencia;
	recursoAvaliacao: IRecursoAvaliacao;
	necessidadesEspeciais: INecessidadesEspeciais[];
	irmaos: IIrmao[];
}

interface Aluno {
	numeroRA: string;
	digitoRA: string;
	siglaUFRA: string;
}

export interface IDadosPessoais {
	numeroRA: string;
	digitoRA: string;
	siglaUFRA: string;
	nomeAluno: string;
	dataNascimento: string;
	corRaca: number | string;
	descricaoCorRaca: string;
	codigoSexo: number | string;
	sexo: string | number;
	nomeMae: string;
	nomePai: string;
	nomeSocial: string;
	nomeAfetivo: string;
	email: string;
	nacionalidade: number | string;
	descricaoNacionalidade: string;
	codigoPaisOrigem: number | string;
	nomePaisOrigem: string;
	dataEntradaPais: string;
	bolsaFamilia: string;
	quilombola: string;
	nomeMunicipioNascimento: string;
	ufMunicipioNascimento: string;
	alunoFalecido: string;
	dataFaleciomento: string;
	codigoMunicipioNascimentoDNE: number | string;
	codigoBolsaFamilia: number | boolean | string;
	possuiInternet: string;
	possuiNotebookSmartphoneTablet: string;
}

export interface IFormDadosPessoais {
	alunoFalecido: string;
	codigoBolsaFamilia: number | boolean;
	codigoMunicipioNascimentoDNE: number;
	codigoPaisOrigem: number;
	codigoSexo: number;
	corRaca: number;
	dataNascimento: string;
	descricaoCorRaca: string;
	descricaoNacionalidade: string;
	digitoRA: string;
	nacionalidade: number;
	nomeAluno: string;
	nomeMae: string;
	nomeMunicipioNascimento: string;
	nomePai: string;
	nomePaisOrigem: string;
	numeroRA: string;
	sexo: string;
	siglaUFRA: string;
	ufMunicipioNascimento: string;
	quilombola: string;
	possuiInternet: string;
	possuiNotebookSmartphoneTablet: string;
}

interface IDocumentos {
	codigoINEP: number | string;
	cpf: string;
	numeroDocumentoCivil: string;
	digitoDocumentoCivil: string;
	ufDocumentoCivil: string;
	dataEmissaoDocumentoCivil: string;
	dataEmissaoCertidao: string;
	tipoCertidao: string;
	numeroNIS: string;
	numeroRG: string;
	digitoRG: string;
	ufRG: string;
	justificativaDocumentos: string;
	dataEmissaoRG: string;
}

interface ICertidaoAntiga {
	numeroCertidao: string;
	numeroLivroRegistro: string;
	numeroFolhaRegistro: string;
	nomeMunicipioComarca: string;
	ufComarca: string;
	distritoNascimento: string;
	// Envio
	folha: string;
	livro: string;
	municipioComarca: string;
	distritoCertidao: string;
	dataEmissaoCertidao: string;
	tipoCertidao: string;
}

interface ICertidaoNova {
	certificadoMatricula01: string;
	certificadoMatricula02: string;
	certificadoMatricula03: string;
	certificadoMatricula04: string;
	certificadoMatricula05: string;
	certificadoMatricula06: string;
	certificadoMatricula07: string;
	certificadoMatricula08: string;
	certificadoMatricula09: string;
	dataEmissaoCertidao?: string;
	tipoCertidao: string;
}

interface IEnderecoResidencial {
	logradouro: string;
	numero: string;
	areaLogradouro: string;
	complemento: string;
	bairro: string;
	nomeCidade: string;
	ufCidade: string;
	latitude: string;
	longitude: string;
	cep: string;
	codigoMunicipioDNE: string | number;
	codigoArea: number | string;
	codigoLocalizacao: number | string;
	localizacaoDiferenciada: string;
}

export interface ITelefone {
	id?: string;
	ddd: string;
	numero: string;
	tipo: number;
	descricaoTipo: string | number;
	complemento: string;
	sms: string;
}

interface ITelefones {
	telefones: ITelefone[];
}

interface IDeficiencia {
	codigo: string | boolean;
	descricao: string | boolean;
	codigoNecessidade: string;
	mobilidadeReduzida: string | number;
	tipoMobilidadeReduzida?: string;
	tipoMobilidadeReduxida?: string;
	cuidador: string | number;
	tipoCuidador: string;
	profSaude: string | number;
	tipoProfSaude: string;
	possuiNecessidades: string | boolean;
}

interface IRecursoAvaliacao {
	tamanhoFonte: number | string;
	guiaInterprete: string;
	interpreteLibras: string;
	leituraLabial: string;
	nenhum: string;
	provaAmpliada: string;
	fonteProva: string;
	provaBraile: string;
	auxilioTranscricao: string;
	auxilioLeitor: string;
	provaVideoLibras: string;
	provaLinguaPortuguesa: string;
	cdAudioDeficienteVisual: string;
}

interface INecessidadesEspeciais {
	codigo: number;
	nome: string;
	tipo?: string;
	lista?: [];
}

interface IIrmao {
	nomeAluno: string;
	dataNascimento: string;
	numeroRA: string;
	digitoRA: string;
	siglaUFRA: string;
	gemeo: string;
}

export const initialValuesTelefone: ITelefones = {
	telefones: [],
};

export const initialValues: IFichaAluno = {
	aluno: {
		numeroRA: '',
		digitoRA: '',
		siglaUFRA: '',
	},
	idProcesso: 0,
	mensagem: '',
	dataAlteracao: '',
	dadosPessoais: {
		numeroRA: '',
		digitoRA: '',
		siglaUFRA: '',
		nomeAluno: '',
		dataNascimento: '',
		corRaca: '',
		descricaoCorRaca: '',
		codigoSexo: '',
		sexo: '',
		nomeMae: '',
		nomePai: '',
		nomeSocial: '',
		nomeAfetivo: '',
		email: '',
		nacionalidade: '',
		descricaoNacionalidade: '',
		codigoPaisOrigem: '',
		nomePaisOrigem: '',
		dataEntradaPais: '',
		bolsaFamilia: '',
		quilombola: '',
		nomeMunicipioNascimento: '',
		ufMunicipioNascimento: '',
		alunoFalecido: '',
		dataFaleciomento: '',
		codigoMunicipioNascimentoDNE: '',
		codigoBolsaFamilia: '',
		possuiInternet: '',
		possuiNotebookSmartphoneTablet: '',
	},
	documentos: {
		codigoINEP: '',
		cpf: '',
		numeroDocumentoCivil: '',
		digitoDocumentoCivil: '',
		ufDocumentoCivil: '',
		dataEmissaoDocumentoCivil: '',
		dataEmissaoCertidao: '',
		tipoCertidao: '',
		numeroNIS: '',
		numeroRG: '',
		digitoRG: '',
		ufRG: '',
		justificativaDocumentos: '',
		dataEmissaoRG: '',
	},
	justificativaDocumentos: '',
	certidaoAntiga: {
		numeroCertidao: '',
		numeroLivroRegistro: '',
		numeroFolhaRegistro: '',
		nomeMunicipioComarca: '',
		ufComarca: '',
		distritoNascimento: '',
		folha: '',
		livro: '',
		municipioComarca: '',
		distritoCertidao: '',
		dataEmissaoCertidao: '',
		tipoCertidao: '',
	},
	certidaoNova: {
		certificadoMatricula01: '',
		certificadoMatricula02: '',
		certificadoMatricula03: '',
		certificadoMatricula04: '',
		certificadoMatricula05: '',
		certificadoMatricula06: '',
		certificadoMatricula07: '',
		certificadoMatricula08: '',
		certificadoMatricula09: '',
		dataEmissaoCertidao: '',
		tipoCertidao: '',
	},
	enderecoResidencial: {
		logradouro: '',
		numero: '',
		areaLogradouro: '',
		complemento: '',
		bairro: '',
		nomeCidade: '',
		ufCidade: 'SP',
		latitude: '',
		longitude: '',
		cep: '',
		codigoMunicipioDNE: '',
		codigoArea: '',
		codigoLocalizacao: '',
		localizacaoDiferenciada: '',
	},
	telefones: [],
	telefone: {
		id: '',
		complemento: '',
		ddd: '',
		descricaoTipo: '',
		numero: '',
		sms: '',
		tipo: 0,
	},
	deficiencia: {
		codigo: '',
		codigoNecessidade: '',
		descricao: '',
		mobilidadeReduzida: '',
		tipoMobilidadeReduzida: '',
		tipoMobilidadeReduxida: '',
		cuidador: '',
		tipoCuidador: '',
		profSaude: '',
		tipoProfSaude: '',
		possuiNecessidades: false,
	},
	recursoAvaliacao: {
		guiaInterprete: '',
		interpreteLibras: '',
		leituraLabial: '',
		nenhum: '',
		provaAmpliada: '',
		tamanhoFonte: '',
		fonteProva: '',
		provaBraile: '',
		auxilioTranscricao: '',
		auxilioLeitor: '',
		provaVideoLibras: '',
		cdAudioDeficienteVisual: '',
		provaLinguaPortuguesa: '',
	},
	necessidadesEspeciais: [],
	irmaos: [],
};

export const initialValuesDadosCadastrais: IDadosPessoais = {
	numeroRA: '',
	digitoRA: '',
	siglaUFRA: '',
	nomeAluno: '',
	dataNascimento: '',
	corRaca: 0,
	descricaoCorRaca: '',
	codigoSexo: 0,
	sexo: '',
	nomeMae: '',
	nomePai: '',
	nomeSocial: '',
	nomeAfetivo: '',
	email: '',
	nacionalidade: 0,
	descricaoNacionalidade: '',
	codigoPaisOrigem: 0,
	nomePaisOrigem: '',
	dataEntradaPais: '',
	bolsaFamilia: '',
	quilombola: '',
	nomeMunicipioNascimento: '',
	ufMunicipioNascimento: '',
	alunoFalecido: '',
	dataFaleciomento: '',
	codigoMunicipioNascimentoDNE: 0,
	codigoBolsaFamilia: 0,
	possuiInternet: '',
	possuiNotebookSmartphoneTablet: '',
};

export const treatSpecialNeeds = (
	values: NecessidadesEspeciais[],
): INecessidadesEspeciais[] => {
	const formattedSpecialNeeds: INecessidadesEspeciais[] = [];

	if (values.length > 0) {
		values.map((item: NecessidadesEspeciais) => {
			formattedSpecialNeeds.push({
				codigo: Number(item.codigoNecessidadeEspecial) || 0,
				nome: item.nomeNecessidadeEspecial || '',
			});

			return null;
		});

		return formattedSpecialNeeds;
	}

	return [];
};

export const treatBrother = (values: Irmao[]): IIrmao[] => {
	const formattedBrotherList: IIrmao[] = [];

	if (values.length > 0) {
		values.map((item: Irmao) => {
			formattedBrotherList.push({
				nomeAluno: item.nomeAluno,
				dataNascimento: item.dataNascimento,
				numeroRA: item.numeroRA,
				digitoRA: item.digitoRA,
				siglaUFRA: item.siglaUFRA,
				gemeo: item.gemeo ? 'S' : 'N',
			});

			return null;
		});

		return formattedBrotherList;
	}

	return [];
};

export const treatPhoneData = (values: Telefone[]): ITelefone[] => {
	const formattedPhoneList: ITelefone[] = [];
	if (values.length > 0) {
		values.map((item: Telefone) => {
			const {
				dddNumero,
				numero,
				tipoTelefone,
				descTipoTelefone,
				complemento,
				sms,
			} = item;

			formattedPhoneList.push({
				id: uuidV4(),
				ddd: dddNumero,
				numero,
				tipo: Number(tipoTelefone) || 0,
				descricaoTipo: descTipoTelefone,
				complemento: complemento === '' ? '' : complemento,
				sms: sms ? 'SIM' : 'NAO',
			});

			return null;
		});

		return formattedPhoneList;
	}

	return [];
};

export const treatInitialValues = ({
	documentos,
	enderecoResidencial,
	deficiencia,
	recursoAvaliacao,
	processoId,
	sucesso,
	dataAlteracaoFicha,
	dadosPessoais,
	justificativaDocumentos,
	certidaoAntiga,
	certidaoNova,
	irmaos,
	listaNecessidadesEspeciais,
	telefones,
}: ConsultaFichaAlunoData): IFichaAluno => {
	const values = {
		telefone: initialValues.telefone,
		necessidadesEspeciais: listaNecessidadesEspeciais
			? treatSpecialNeeds(listaNecessidadesEspeciais)
			: [],
		irmaos: irmaos ? treatBrother(irmaos) : [],
		telefones: telefones ? treatPhoneData(telefones) : [],
		idProcesso: Number(processoId) || 0,
		mensagem: sucesso || '',
		dataAlteracao:
			(dataAlteracaoFicha && formatDateISOWithOutUTC(dataAlteracaoFicha)) || '',
		justificativaDocumentos: justificativaDocumentos || '',
		aluno: {
			numeroRA: dadosPessoais?.numRA?.trim() || '',
			digitoRA: dadosPessoais?.digitoRA?.trim() || '',
			siglaUFRA: dadosPessoais?.siglaUFRA || '',
		},
		certidaoNova: {
			certificadoMatricula01: certidaoNova?.certMatr01 || '',
			certificadoMatricula02: certidaoNova?.certMatr02 || '',
			certificadoMatricula03: certidaoNova?.certMatr03 || '',
			certificadoMatricula04: certidaoNova?.certMatr04 || '',
			certificadoMatricula05: certidaoNova?.certMatr05 || '',
			certificadoMatricula06: certidaoNova?.certMatr06 || '',
			certificadoMatricula07: certidaoNova?.certMatr07 || '',
			certificadoMatricula08: certidaoNova?.certMatr08 || '',
			certificadoMatricula09: certidaoNova?.certMatr09 || '',
			dataEmissaoCertidao:
				(certidaoNova?.certMatr01 &&
					documentos.dataEmissaoCertidao &&
					formatDateISOWithOutUTC(documentos?.dataEmissaoCertidao)) ||
				'',
			tipoCertidao: '',
		},
		certidaoAntiga: {
			numeroCertidao: certidaoAntiga?.numCertidao || '',
			numeroLivroRegistro: certidaoAntiga?.livro || '',
			numeroFolhaRegistro: certidaoAntiga?.folha || '',
			nomeMunicipioComarca: certidaoAntiga?.municipioComarca || '',
			distritoNascimento: certidaoAntiga?.distritoCertidao || '',
			folha: certidaoAntiga?.folha || '',
			livro: certidaoAntiga?.livro || '',
			municipioComarca: certidaoAntiga?.municipioComarca || '',
			distritoCertidao: certidaoAntiga?.distritoCertidao || '',
			tipoCertidao: '',
			dataEmissaoCertidao:
				(certidaoAntiga?.numCertidao &&
					documentos?.dataEmissaoCertidao &&
					formatDateISOWithOutUTC(documentos?.dataEmissaoCertidao)) ||
				'',
			ufComarca: certidaoAntiga?.ufComarca || '',
		},
		dadosPessoais: {
			numeroRA: dadosPessoais?.numRA?.trim() || '',
			digitoRA: dadosPessoais?.digitoRA?.trim() || '',
			siglaUFRA: dadosPessoais?.siglaUFRA || '',
			nomeAluno: dadosPessoais?.nomeAluno || '',
			dataNascimento:
				(dadosPessoais?.dataNascimento &&
					formatDateISOWithOutUTC(dadosPessoais?.dataNascimento)) ||
				'',
			corRaca: Number(dadosPessoais?.corRaca) || 0,
			descricaoCorRaca: dadosPessoais?.corRaca || '',
			codigoSexo: Number(dadosPessoais?.codSexo) || 0,
			sexo: dadosPessoais?.sexo?.toString() || '',
			nomeMae: dadosPessoais?.nomeMae || '',
			nomePai: dadosPessoais?.nomePai || '',
			nomeSocial: dadosPessoais?.nomeSocial || '',
			nomeAfetivo: dadosPessoais?.nomeAfetivo || '',
			email: dadosPessoais?.email || '',
			nacionalidade: Number(dadosPessoais?.nacionalidade) || 0,
			descricaoNacionalidade: dadosPessoais?.descricaoNacionalidade || '',
			codigoPaisOrigem: dadosPessoais?.codPaisOrigem,
			nomePaisOrigem: dadosPessoais?.nomePaisOrigem || '',
			dataEntradaPais:
				(dadosPessoais?.dataEntradaPais &&
					formatDateISOWithOutUTC(dadosPessoais?.dataEntradaPais)) ||
				'',
			bolsaFamilia: dadosPessoais?.bolsaFamilia ? 'S' : '',
			quilombola: dadosPessoais?.quilombola ? 'S' : '',
			nomeMunicipioNascimento: dadosPessoais?.nomeMunicipioNascimento || '',
			ufMunicipioNascimento: dadosPessoais?.ufMunicipioNascimento || '',
			alunoFalecido: dadosPessoais?.alunoFalecido ? 'S' : '',
			dataFaleciomento:
				(dadosPessoais?.dataFalecimento &&
					formatDateISOWithOutUTC(dadosPessoais?.dataFalecimento)) ||
				'',
			codigoMunicipioNascimentoDNE:
				Number(dadosPessoais?.codMunicipioNascimentoDNE) || 0,
			codigoBolsaFamilia: 0,
			possuiInternet: dadosPessoais?.possuiInternet ? 'S' : '',
			possuiNotebookSmartphoneTablet:
				dadosPessoais?.possuiNotebookSmartphoneTablet ? 'S' : '',
		},
		enderecoResidencial: {
			logradouro: enderecoResidencial?.logradouro || '',
			numero: enderecoResidencial?.numero || '',
			complemento: enderecoResidencial?.complemento || '',
			bairro: enderecoResidencial?.bairro || '',
			nomeCidade: enderecoResidencial?.nomeCidade || '',
			ufCidade: enderecoResidencial?.ufCidade || '',
			// latitude: enderecoResidencial?.latitude || '-23.5489',
			// longitude: enderecoResidencial?.longitude || '-46.6388',
			latitude: enderecoResidencial.latitude || '',
			longitude: enderecoResidencial.longitude || '',
			cep: enderecoResidencial?.cep || '',
			codigoMunicipioDNE: enderecoResidencial?.codMunicipioDNE || 0,
			codigoArea: enderecoResidencial?.codArea || 0,
			codigoLocalizacao: enderecoResidencial?.codLocalizacao || 0,
			localizacaoDiferenciada:
				enderecoResidencial?.localizacaoDiferenciada || '',
			areaLogradouro: enderecoResidencial?.codArea || '',
		},
		documentos: {
			codigoINEP: documentos?.codigoINEP || 0,
			cpf: documentos?.cpf || '',
			numeroDocumentoCivil: documentos?.numDocumentoCivil || '',
			digitoDocumentoCivil: documentos?.digitoDocumentoCivil || '',
			ufDocumentoCivil: documentos?.ufDocumentoCivil || '',
			dataEmissaoDocumentoCivil:
				(documentos?.dataEmissaoDocumentoCivil &&
					formatDateISOWithOutUTC(documentos?.dataEmissaoDocumentoCivil)) ||
				'',
			dataEmissaoCertidao:
				(documentos?.dataEmissaoCertidao &&
					formatDateISOWithOutUTC(documentos?.dataEmissaoCertidao)) ||
				'',
			tipoCertidao: '',
			numeroNIS: documentos?.numeroNis || '',
			numeroRG: documentos?.numDocumentoCivil || '',
			digitoRG: documentos?.digitoDocumentoCivil || '',
			ufRG: documentos?.ufDocumentoCivil || '',
			justificativaDocumentos: justificativaDocumentos || '',
			dataEmissaoRG:
				(documentos?.dataEmissaoDocumentoCivil &&
					formatDateISOWithOutUTC(documentos?.dataEmissaoDocumentoCivil)) ||
				'',
		},
		deficiencia: {
			codigo: '',
			descricao: '',
			mobilidadeReduzida: deficiencia?.mobilidadeReduzida?.toString() || '',
			tipoMobilidadeReduzida: deficiencia?.tipoMobilidadeReduzida || '',
			cuidador: '',
			tipoCuidador: '',
			profSaude: '',
			tipoProfSaude: '',
			codigoNecessidade: '',
			tipoMobilidadeReduxida: '',
			possuiNecessidades: !!deficiencia?.mobilidadeReduzida,
		},
		recursoAvaliacao: {
			guiaInterprete: recursoAvaliacao?.guiaInterprete ? 'SIM' : '',
			interpreteLibras: recursoAvaliacao?.interpreteLibras ? 'SIM' : '',
			leituraLabial: recursoAvaliacao?.leituraLabial ? 'SIM' : '',
			nenhum: recursoAvaliacao?.nenhum ? 'SIM' : '',
			provaAmpliada: recursoAvaliacao?.provaAmpliada ? 'SIM' : '',
			tamanhoFonte: recursoAvaliacao?.tamanhoFonte || 0,
			fonteProva: recursoAvaliacao?.tamanhoFonte?.toString() || '',
			provaBraile: recursoAvaliacao?.provaBraile ? 'SIM' : '',
			auxilioTranscricao: recursoAvaliacao?.auxilioTranscricao ? 'SIM' : '',
			auxilioLeitor: recursoAvaliacao?.auxilioLeitor ? 'SIM' : '',
			provaVideoLibras: recursoAvaliacao?.provaVideoLibras ? 'SIM' : '',
			cdAudioDeficienteVisual: recursoAvaliacao?.cdAudioDefVisual ? 'SIM' : '',
			provaLinguaPortuguesa: recursoAvaliacao?.provaLinguaPortuguesa
				? 'SIM'
				: '',
		},
	};

	return values;
};

export const schema = Yup.object<IFichaAluno>().shape({
	// DADOS PESSOAIS
	dadosPessoais: Yup.object().shape({
		nomeAluno: Yup.string()
			.required(`Campo Obrigatório: Nome`)
			.test({
				name: 'onlyLetters',
				exclusive: true,
				message: 'Campo Nome: Apenas letras são permitidas.',
				test: value => onlyLetters(value),
			})
			.test({
				name: 'minCharacters',
				exclusive: true,
				message: 'Nome deve conter no mínimo 8 caracteres',
				test: value => minCharacters(8, value),
			}),
		sexo: Yup.string().required(`Campo Obrigatório: Sexo`),
		email: Yup.string().email('O Email capturado está errado'),
		corRaca: Yup.string().required(`Campo Obrigatório: Raça/Cor`),
		dataNascimento: Yup.string().required(
			`Campo Obrigatório: Data de Nascimento`,
		),
		nomeMae: Yup.string().required(`Campo Obrigatório: Nome da Mãe`),
		nacionalidade: Yup.string().required(`Campo Obrigatório: Nacionalidade`),
		ufMunicipioNascimento: Yup.string().when('nacionalidade', {
			is: nacionalidade => nacionalidade === 1 || nacionalidade === '1',
			then: Yup.string().required(`Campo Obrigatório: UF de Nascimento`),
		}),
		nomeMunicipioNascimento: Yup.string().when('nacionalidade', {
			is: nacionalidade => nacionalidade === 1 || nacionalidade === '1',
			then: Yup.string().required(`Campo Obrigatório: Município de Nascimento`),
		}),
		dataEntradaPais: Yup.string().when(['nacionalidade'], {
			is: nacionalidade =>
				nacionalidade !== 1 && nacionalidade !== '1' && nacionalidade,
			then: Yup.string().required(`Campo Obrigatório: Data Entrada no Brasil`),
		}),
	}),

	// DOCUMENTOS
	documentos: Yup.object().shape(
		{
			numeroDocumentoCivil: Yup.string().when(
				['ufDocumentoCivil', 'dataEmissaoDocumentoCivil'],
				{
					is: (ufDocumentoCivil, dataEmissaoDocumentoCivil) =>
						(ufDocumentoCivil !== '' && ufDocumentoCivil !== 0) ||
						dataEmissaoDocumentoCivil !== '',
					then: Yup.string().required(`Campo Obrigatório: Documento Civil RG`),
				},
			),
			ufDocumentoCivil: Yup.string().when(
				['numeroDocumentoCivil', 'dataEmissaoDocumentoCivil'],
				{
					is: (numeroDocumentoCivil, dataEmissaoDocumentoCivil) =>
						numeroDocumentoCivil !== '' || dataEmissaoDocumentoCivil !== '',
					then: Yup.string().required(`Campo Obrigatório: UF RG`),
				},
			),
			dataEmissaoDocumentoCivil: Yup.string().when(
				['numeroDocumentoCivil', 'ufDocumentoCivil'],
				{
					is: (numeroDocumentoCivil, ufDocumentoCivil) =>
						numeroDocumentoCivil !== '' || ufDocumentoCivil !== '',
					then: Yup.string().required(`Campo Obrigatório: Data Emissão RG/RNE`),
				},
			),
			tipoCertidao: Yup.string().when(
				['numeroDocumentoCivil', 'cpf', 'numeroNIS'],
				{
					is: (numeroDocumentoCivil, cpf, numeroNIS) =>
						numeroDocumentoCivil === '' && cpf === '' && numeroNIS === '',
					then: Yup.string().required(`Necessário preencher um dos documentos`),
				},
			),
		},
		[
			['numeroDocumentoCivil', 'ufDocumentoCivil'],
			['numeroDocumentoCivil', 'dataEmissaoDocumentoCivil'],
			['ufDocumentoCivil', 'dataEmissaoDocumentoCivil'],
		],
	),
	certidaoNova: Yup.object().shape({
		certificadoMatricula01: Yup.string().when(
			[
				'tipoCertidao',
				'certificadoMatricula02',
				'certificadoMatricula03',
				'certificadoMatricula04',
				'certificadoMatricula05',
				'certificadoMatricula06',
				'certificadoMatricula07',
				'certificadoMatricula08',
				'certificadoMatricula09',
			],
			{
				is: (
					tipoCertidao,
					certificadoMatricula02,
					certificadoMatricula03,
					certificadoMatricula04,
					certificadoMatricula05,
					certificadoMatricula06,
					certificadoMatricula07,
					certificadoMatricula08,
					certificadoMatricula09,
					certificadoMatricula01,
				) =>
					tipoCertidao === 'NOVA' &&
					(certificadoMatricula01 === '' ||
						certificadoMatricula02 === '' ||
						certificadoMatricula03 === '' ||
						certificadoMatricula04 === '' ||
						certificadoMatricula05 === '' ||
						certificadoMatricula06 === '' ||
						certificadoMatricula07 === '' ||
						certificadoMatricula08 === '' ||
						certificadoMatricula09 === ''),
				then: Yup.string().test({
					name: 'validation',
					exclusive: true,
					message: 'Campo Obrigatório: Matrícula',
					test: () => false,
				}),
			},
		),
		dataEmissaoCertidao: Yup.string().when(['tipoCertidao'], {
			is: tipoCertidao => tipoCertidao === 'NOVA',
			then: Yup.string().required(`Campo Obrigatório: Data de emissão`),
		}),
	}),
	certidaoAntiga: Yup.object().shape({
		dataEmissaoCertidao: Yup.string().when(['tipoCertidao'], {
			is: tipoCertidao => tipoCertidao === 'ANTIGA',
			then: Yup.string().required(`Campo Obrigatório: Data de emissão`),
		}),
		numeroCertidao: Yup.string().when(['tipoCertidao'], {
			is: tipoCertidao => tipoCertidao === 'ANTIGA',
			then: Yup.string().required(`Campo Obrigatório: Número`),
		}),
		numeroFolhaRegistro: Yup.string().when(['tipoCertidao'], {
			is: tipoCertidao => tipoCertidao === 'ANTIGA',
			then: Yup.string().required(`Campo Obrigatório: Folha`),
		}),
		numeroLivroRegistro: Yup.string().when(['tipoCertidao'], {
			is: tipoCertidao => tipoCertidao === 'ANTIGA',
			then: Yup.string().required(`Campo Obrigatório: Livro`),
		}),
		distritoNascimento: Yup.string().when(['tipoCertidao'], {
			is: tipoCertidao => tipoCertidao === 'ANTIGA',
			then: Yup.string().required(
				`Campo Obrigatório: Distrito Certidão Nascimento`,
			),
		}),
		nomeMunicipioComarca: Yup.string().when(['tipoCertidao'], {
			is: tipoCertidao => tipoCertidao === 'ANTIGA',
			then: Yup.string().required(`Campo Obrigatório: Município da Comarca`),
		}),
		ufComarca: Yup.string().when(['tipoCertidao'], {
			is: tipoCertidao => tipoCertidao === 'ANTIGA',
			then: Yup.string().required(`Campo Obrigatório: UF da Comarca`),
		}),
	}),

	// DEFICIÊNCIA
	deficiencia: Yup.object().shape({
		tipoCuidador: Yup.string().when(['cuidador'], {
			is: cuidador => cuidador === true,
			then: Yup.string().required(`Campo Obrigatório: Cuidador`),
		}),
		tipoProfSaude: Yup.string().when(['profSaude'], {
			is: profSaude => profSaude === true,
			then: Yup.string().required(`Campo Obrigatório: Profissional Saúde`),
		}),
		tipoMobilidadeReduzida: Yup.string().when(['mobilidadeReduzida'], {
			is: mobilidadeReduzida => mobilidadeReduzida === true,
			then: Yup.string().required(`Campo Obrigatório: Mobilidade Reduzida`),
		}),
	}),

	recursoAvaliacao: Yup.object().shape({
		tamanhoFonte: Yup.string().when(['provaAmpliada'], {
			is: provaAmpliada => provaAmpliada === true,
			then: Yup.string().required(
				`Campo Obrigatório: Prova Ampliada (Tamanho da Fonte)`,
			),
		}),
	}),

	// ENDEREÇO RESIDENCIAL
	enderecoResidencial: Yup.object().shape({
		cep: Yup.string().required(`Campo Obrigatório: CEP`),
		areaLogradouro: Yup.string().required(
			`Campo Obrigatório: Localização/Zona de Residência`,
		),
		logradouro: Yup.string().required(`Campo Obrigatório: Endereço`),
		numero: Yup.string().required(`Campo Obrigatório: N°`),
		bairro: Yup.string().required(`Campo Obrigatório: Bairro`),
		nomeCidade: Yup.string().required(`Campo Obrigatório: Cidade - UF`),
		// latitude: Yup.string().required(`Campo Obrigatório: Latitude`),
		// longitude: Yup.string().required(`Campo Obrigatório: Longitude`),
	}),
});

export const schemaTelefone = Yup.object<ITelefone>().shape({
	telefone: Yup.object().shape({
		descricaoTipo: Yup.string().required(`Campo Obrigatório: Tipo Telefone`),
		sms: Yup.string().when(['descricaoTipo'], {
			is: descricaoTipo => descricaoTipo === 'Celular',
			then: Yup.string().required(`Campo Obrigatório: Aceita SMS`),
		}),
		ddd: Yup.string().required(`Campo Obrigatório: DDD`),
		numero: Yup.string().required(`Campo Obrigatório: Número`),
	}),
});

export function formatValuesCreate(formValues: IFichaAluno): FichaAlunoEnvio {
	const {
		dadosPessoais,
		documentos,
		enderecoResidencial,
		// deficiencia,
		recursoAvaliacao,
		certidaoAntiga,
		certidaoNova,
	} = formValues;

	const updatedDeficiencia: DeficienciaCadastroFichaAluno = {
		// codigoNecessidade: deficiencia.codigoNecessidade,
		codigoNecessidade: '',
		// mobilidadeReduzida: !!deficiencia.mobilidadeReduzida,
		mobilidadeReduzida: false,
		// tipoMobilidadeReduzida:
		// 	(deficiencia.mobilidadeReduzida && deficiencia.tipoMobilidadeReduzida) ||
		// 	'',
		tipoMobilidadeReduzida: '',
		professorAuxiliar: false,
		dataInicioProfessorAuxiliar: '', // '2022-01-01';
		dataFimProfessorAuxiliar: '', // '2026-01-01';
		dataInicioMobilidadeReduzida: '',
		dataFimMobilidadeReduzida: '',
		profissionalApoioEscolarVO: {
			profissionalApoioEscolar: false,
			atividadeDiaria: false,
			atividadeEscolar: false,
			apoioHigiene: false,
			apoioLocomocao: false,
			apoioAlimentacao: false,
			dataInicioVigenciaAtividadeDiaria: '', // '2022-01-01';
			dataFimVigenciaAtividadeDiaria: '', // '2023-01-01';
			dataInicioVigenciaAtividadeEscolar: '', // '2022-01-01';
			dataFimVigenciaAtividadeEscolar: '', // '2023-01-01';
		},
	};

	const updatedRecursoAvaliacao: RecursoAvaliacaoCadastroFichaAluno = {
		guiaInterprete: !!recursoAvaliacao.guiaInterprete,
		interpreteLibras: !!recursoAvaliacao.interpreteLibras,
		leituraLabial: !!recursoAvaliacao.leituraLabial,
		nenhum: !!recursoAvaliacao.nenhum,
		provaAmpliada: !!recursoAvaliacao.provaAmpliada,
		fonteProva: recursoAvaliacao.fonteProva,
		provaBraile: !!recursoAvaliacao.provaBraile,
		auxilioTranscricao: !!recursoAvaliacao.auxilioTranscricao,
		auxilioLeitor: !!recursoAvaliacao.auxilioLeitor,
		provaVideoLibras: !!recursoAvaliacao.provaVideoLibras,
		provaLinguaPortuguesa: !!recursoAvaliacao.provaLinguaPortuguesa,
		cdAudioDefVisual: !!recursoAvaliacao.cdAudioDeficienteVisual,
		fonteProva18: false,
		fonteProva20: false,
	};

	const requestValues: FichaAlunoEnvio = {
		dadosPessoais: {
			nomeAluno: dadosPessoais.nomeAluno,
			nomeMae: dadosPessoais.nomeMae,
			nomePai: dadosPessoais.nomePai,
			nomeSocial: dadosPessoais.nomeSocial,
			nomeAfetivo: dadosPessoais.nomeAfetivo,
			dataNascimento:
				(dadosPessoais.dataNascimento?.includes('/') &&
					convertDate(dadosPessoais.dataNascimento)) ||
				dadosPessoais.dataNascimento ||
				'',
			corRaca: dadosPessoais.corRaca?.toString() || '',
			sexo: dadosPessoais.codigoSexo?.toString() || '',
			email: dadosPessoais.email,
			nacionalidade: dadosPessoais.nacionalidade?.toString() || '',
			nomeMunicipioNascimento: dadosPessoais.nomeMunicipioNascimento,
			ufMunicipioNascimento: dadosPessoais.ufMunicipioNascimento,
			codPaisOrigem: dadosPessoais.codigoPaisOrigem?.toString() || '',
			paisOrigem: dadosPessoais.nomePaisOrigem?.toString() || '',
			codMunicipioNascimentoDNE:
				dadosPessoais.codigoMunicipioNascimentoDNE?.toString() || '',
			dataEntradaPais:
				(dadosPessoais.dataEntradaPais?.includes('/') &&
					convertDate(dadosPessoais.dataEntradaPais)) ||
				dadosPessoais.dataEntradaPais ||
				'',
			bolsaFamilia: !!dadosPessoais.codigoBolsaFamilia,
			quilombola: !!dadosPessoais.quilombola,
			possuiInternet: !!dadosPessoais.possuiInternet,
			possuiNotebookSmartphoneTablet:
				!!dadosPessoais.possuiNotebookSmartphoneTablet,
			tipoSanguineo: '',
			doadorOrgaos: false,
			flagSigilo: '',
		},
		documentos: {
			codigoINEP: documentos.codigoINEP?.toString() || '',
			cpf: limparMascara(documentos.cpf),
			numeroNis: documentos.numeroNIS,
			numDocumentoCivil: limparMascara(documentos.numeroDocumentoCivil),
			digitoDocumentoCivil: limparMascara(documentos.digitoDocumentoCivil),
			ufDocumentoCivil: documentos.ufDocumentoCivil,
			dataEmissaoDocumentoCivil:
				(documentos.dataEmissaoDocumentoCivil?.includes('/') &&
					convertDate(documentos.dataEmissaoDocumentoCivil)) ||
				documentos.dataEmissaoDocumentoCivil ||
				'',

			justificativaDocumentos: '',
			numeroCns: '',
		},
		enderecoResidencial: {
			logradouro: enderecoResidencial.logradouro,
			numero: enderecoResidencial.numero,
			areaLogradouro: enderecoResidencial.areaLogradouro,
			complemento: enderecoResidencial.complemento,
			bairro: enderecoResidencial.bairro,
			cep: enderecoResidencial.cep,
			nomeCidade: enderecoResidencial.nomeCidade,
			ufCidade: enderecoResidencial.ufCidade,
			codMunicipioDNE: enderecoResidencial.codigoMunicipioDNE?.toString() || '',
			latitude: enderecoResidencial.latitude?.toString().trim() || '',
			longitude: enderecoResidencial.longitude?.toString().trim() || '',
			codLocalizacaoDiferenciada:
				enderecoResidencial.codigoLocalizacao?.toString() || '',
		},
		deficiencia: updatedDeficiencia,
		recursoAvaliacao: updatedRecursoAvaliacao,
		certidaoAntiga: {
			folha: certidaoAntiga.numeroFolhaRegistro,
			livro: certidaoAntiga.numeroLivroRegistro,
			numCertidao: certidaoAntiga.numeroCertidao,
			municipioComarca: certidaoAntiga.nomeMunicipioComarca,
			codMunicComarcaDNE: '',
			ufComarca: certidaoAntiga.ufComarca,
			distritoCertidao: certidaoAntiga.distritoNascimento,
			dataEmissaoCertidao:
				(certidaoAntiga.dataEmissaoCertidao?.includes('/') &&
					convertDate(certidaoAntiga.dataEmissaoCertidao)) ||
				certidaoAntiga.dataEmissaoCertidao ||
				'',
		},
		certidaoNova: {
			certMatr01: certidaoNova.certificadoMatricula01,
			certMatr02: certidaoNova.certificadoMatricula02,
			certMatr03: certidaoNova.certificadoMatricula03,
			certMatr04: certidaoNova.certificadoMatricula04,
			certMatr05: certidaoNova.certificadoMatricula05,
			certMatr06: certidaoNova.certificadoMatricula06,
			certMatr07: certidaoNova.certificadoMatricula07,
			certMatr08: certidaoNova.certificadoMatricula08,
			certMatr09: certidaoNova.certificadoMatricula09,
			dataEmissaoCertidao:
				(certidaoNova.dataEmissaoCertidao?.includes('/') &&
					convertDate(certidaoNova.dataEmissaoCertidao)) ||
				certidaoNova.dataEmissaoCertidao ||
				'',
		},
		rastreio: {
			usuarioRemoto: '',
			nomeUsuario: '',
			numCPF: '',
			localPerfilAcesso: '',
		},
	};

	if (certidaoNova.tipoCertidao === 'NOVA') {
		requestValues.certidaoAntiga = {
			folha: '',
			livro: '',
			numCertidao: '',
			municipioComarca: '',
			codMunicComarcaDNE: '',
			ufComarca: '',
			distritoCertidao: '',
			dataEmissaoCertidao: '',
		};
	} else if (certidaoAntiga.tipoCertidao === 'ANTIGA') {
		requestValues.certidaoNova = {
			certMatr01: '',
			certMatr02: '',
			certMatr03: '',
			certMatr04: '',
			certMatr05: '',
			certMatr06: '',
			certMatr07: '',
			certMatr08: '',
			certMatr09: '',
			dataEmissaoCertidao: '',
		};
	}

	return requestValues;
}

export function formatValuesUpdate(
	formValues: IFichaAluno,
): AlterarFichaAlunoRequestData {
	const { aluno, telefones } = formValues;

	const updatedTelefones: AlterarFichaAlunoTelefone[] =
		telefones?.map(telefone => ({
			dddNumero: telefone.ddd,
			numero: telefone.numero.toString() || '',
			tipoTelefone: telefone.tipo?.toString() || '',
			descricaoTipo: telefone.descricaoTipo?.toString() || '',
			complemento: telefone.complemento,
			sms: !!telefone.sms,
		})) || [];

	const valuesRequestCreate = formatValuesCreate(formValues);

	const requestValues: AlterarFichaAlunoRequestData = {
		...valuesRequestCreate,
		aluno: {
			numRA: aluno.numeroRA,
			digitoRA: aluno.digitoRA,
			siglaUFRA: aluno.siglaUFRA,
		},
		dadosPessoais: {
			...valuesRequestCreate.dadosPessoais,
			dataNascimento:
				(valuesRequestCreate.dadosPessoais?.dataNascimento?.includes('/') &&
					convertDate(valuesRequestCreate.dadosPessoais.dataNascimento)) ||
				valuesRequestCreate.dadosPessoais.dataNascimento ||
				'',
		},
		telefones: updatedTelefones,
	};

	return requestValues;
}
