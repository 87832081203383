/* eslint-disable no-param-reassign */
export function formatacaoCid(string: string): string {
	if (string) return string.replace(/[^a-zA-Z0-9.]/gi, '');
	return '';
}

export function letrasRemoveApostrofoAcentoGraveAgudoDuploEspacoCedilhaAcentos(
	string: string,
): string {
	if (string)
		return string
			.replace(/[\u00C0-\u00FC]/g, match => {
				return match.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
			})
			.replace(/[|'`´-]/g, ' ')
			.replace(/\s\s+/g, ' ');
	return '';
}

export function letrasNumerosPontosHifen(string: string): string {
	if (string) return string.replaceAll(/[^-.~a-zÀ-ÖØ-öø-ÿ0-9 ]/gi, '');
	return '';
}

export function letrasAcentosApostrofo(string: string): string {
	if (string) return string.replace(/[^a-zA-Zà-úÀ-Ú-'--´\s ]/g, '');
	return '';
}

export function letrasAcentos(string: string): string {
	if (string) return string.replace(/[^a-zA-Zà-úÀ-Ú\s]/g, '');
	return '';
}

export function letrasAcentosNumeros(string: string): string {
	if (string) return string.replace(/[^a-zA-Zà-úÀ-Ú-0-9\s]/g, '');
	return '';
}

export function letrasNumeros(string: string): string {
	if (string) return string.replace(/[^a-zA-Z-0-9\s]/g, '');
	return '';
}

export function letras(string: string): string {
	if (string) return string.replace(/[^a-zA-Z\s]/g, '');
	return '';
}

export function numeros(string: string): string {
	if (string) return string.replace(/[^0-9]/g, '');
	return '';
}

export function tratarEmailProcon(string: string): string {
	if (string)
		return string.toLowerCase().replace(/[^a-zA-Z-0-9-.-_---@\s]/g, '');
	return '';
}

export function tratarTipoProcon(string: string): string {
	if (string) return string.replace(/[^a-zA-Z\s]/g, '');
	return '';
}

export function tratarSituacaoAtualProcon(string: string): string {
	if (string) return string.replace(/[^a-zA-Z---/\s]/g, '');
	return '';
}

export function nomeIirgdA(string: string): string {
	if (string) return string.replace(/[^-~a-zÀ-ÖØ-öø-ÿ0-9-'-´ ]/gi, '');
	return '';
}

export function nomePaisIirgdA(string: string): string {
	if (string) return string.replace(/[^-~a-zÀ-ÖØ-öø-ÿ0-9-'-´, ]/gi, '');
	return '';
}

export function numerosBarra(string: string): string {
	if (string) return string.replace(/[^0-9-/\s]/g, '');
	return '';
}

export function maskTelefoneCelular(string: string) {
	if (string) {
		string = string.replace(/\D/g, '');
		string = string.replace(/^(\d{2})(\d)/g, '($1) $2');
		string = string.replace(/(\d{4,5}-?)(\d{4})$/, '$1-$2');
	}
	return string;
}

export function maskRg(string: string | null | undefined) {
	if (string) {
		const newString = string.replace(/\D/g, '');
		return newString.replace(/^(\d{2})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4');
	}
	return '';
}

export function maskRgX(string: string | null | undefined) {
	if (string) {
		return string.replace(/^(\d{2})(\d{3})(\d{3})(\d{1}|X)$/, '$1.$2.$3-$4');
	}
	return '';
}

export function maskCPF(string: string | null | undefined) {
	if (string !== undefined && string !== null) {
		string = string.replace(
			/([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/g,
			'$1.$2.$3-$4',
		);
	}
	return string;
}

export function maskCNPJ(string: string | null | undefined) {
	if (string !== undefined && string !== null) {
		string = string.replace(
			/([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/g,
			'$1.$2.$3/$4-$5',
		);
	}
	return string;
}

export function trimValues(
	string: string,
	defaultUnfilledValue: 'null' | 'stringVazia' = 'stringVazia',
) {
	if (
		(string === undefined || string === null) &&
		defaultUnfilledValue === 'stringVazia'
	)
		return '';

	if (
		(string === undefined || string === null) &&
		defaultUnfilledValue === 'null'
	)
		return null;

	return string.trim();
}

export function isFilledValue(string: any, stringDefault?: string) {
	if (
		(typeof string === 'string' &&
			(string === undefined ||
				string === null ||
				string.trim() === '' ||
				string.trim() === '0')) ||
		(typeof string === 'number' &&
			(string === undefined || string === null || string === 0)) ||
		!string
	)
		return stringDefault || 'Não informado';

	if (Array.isArray(string)) {
		let newString: any = '';

		let length: boolean = false;

		string.forEach((item: any, index: number) => {
			length = string.length === index + 1;

			if (
				(item !== undefined &&
					item !== null &&
					typeof item === 'string' &&
					item.trim() !== '' &&
					item.trim() !== '0') ||
				(item !== undefined &&
					item !== null &&
					typeof item === 'number' &&
					item !== 0)
			) {
				newString = newString?.concat(`${item} ${length ? '' : '- '}`);
			} else if (length) {
				newString = newString?.concat('Não informado');
			} else {
				newString = newString?.concat('');
			}
		});

		if (
			newString !== undefined &&
			newString !== null &&
			newString.trim() !== ''
		)
			return newString;

		return stringDefault || 'Não informado';
	}

	return string;
}

export function removeAccents(value: string): string {
	if (!value) return '';

	return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function removeSpecialChars(string: string) {
	if (!string) return '';
	return string.replace(/[^-.,'`"@_!&{}()a-zA-Z0-9 \n]/gi, '');
}

export function changeBreakLineToSpace(string: string) {
	if (!string) return '';
	return string.replace(/[\n]/gi, ' ');
}

export function removeSpecialCharsBreakLineAndNormalizeString(string: string) {
	if (!string) return '';

	const textWithoutAccents = removeAccents(string);
	const textWithoutAccentsAndBreakLine =
		changeBreakLineToSpace(textWithoutAccents);
	const textWithoutAccentsBreakLineAndSpecialChars = removeSpecialChars(
		textWithoutAccentsAndBreakLine,
	);

	return textWithoutAccentsBreakLineAndSpecialChars;
}
