import { all } from 'redux-saga/effects';

import listaCep from './endereco/lista-cep/saga';
import pais from './endereco/pais/saga';
import consultarDigitais from './consultarDigitais/saga';
import confrontarDigitais from './confrontarDigitais/saga';
import confrontarDigitaisCnh from './confrontarDigitaisCnh/saga';
import enderecoIp from './enderecoIp/saga';
import consultaCidadesUF from './consultaCidadesUF/saga';
import consultarFotosRg from './consultarFotosRg/saga';
import enderecos from './enderecos/saga';
import bcadastro from './bcadastro/saga';
import baixarArquivos from './arquivos/baixarArquivos/saga';

export default all([
	listaCep,
	pais,
	consultarDigitais,
	confrontarDigitais,
	confrontarDigitaisCnh,
	enderecoIp,
	consultaCidadesUF,
	consultarFotosRg,
	enderecos,
	bcadastro,
	baixarArquivos,
]);
