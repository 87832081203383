import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { simularEfetuarAcordoRequest } from 'store/modules/api/cdhu/simularEfetuarAcordo/actions';

// PATHS
import { ROUTE_CDHU_SERVICOS_SUCESSO } from 'pages/cdhu/routes/paths';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import { formatCurrency } from 'utils/numberFunctions';
import { formatDate } from 'utils/genericFunctions';

type DataAcordoPadrao = {
	qtdParcelasAcordo: string;
	valorParcelaInicial: string;
	valorParcelaAcordo: string;
	novaPrestacaoLiquida: string;
	dataPrestacaoInicial: string;
	dataPrestacaoFinal: string;
}

const AcordoPadrao: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { token } = useContext<IAuthContext>(AuthContext);

	const [hasSubmit, setHasSubmit] = useState<boolean>(false);
	const [contRequestAcordo, setContRequestAcordo] = useState<number>(0);
	const [dataAcordoPadrao, setDataAcordoPadrao] = useState<DataAcordoPadrao>(
		{
			qtdParcelasAcordo: '',
			valorParcelaInicial: '',
			valorParcelaAcordo: '',
			novaPrestacaoLiquida: '',
			dataPrestacaoInicial: '',
			dataPrestacaoFinal: '',
		}
	)

	const {
		consultarMutuario,
		gerarOcorrencia,
		simularEfetuarAcordo,
	} = useSelector((state: ApplicationState) => state.api.cdhu);

	useEffect(() => {
		if (hasSubmit && simularEfetuarAcordo.status === 200) {
			history.push(ROUTE_CDHU_SERVICOS_SUCESSO);
		}
	}, [hasSubmit, simularEfetuarAcordo, history]);

		useEffect(() => {
			if (simularEfetuarAcordo.data && simularEfetuarAcordo.status === 200 && contRequestAcordo === 0) {
			setDataAcordoPadrao({
				qtdParcelasAcordo: simularEfetuarAcordo.data.qtdParcelasAcordo,
				valorParcelaInicial: simularEfetuarAcordo.data.valorParcelaInicial,
				valorParcelaAcordo: simularEfetuarAcordo.data.valorParcelaAcordo,
				novaPrestacaoLiquida: simularEfetuarAcordo.data.novaPrestacaoLiquida,
				dataPrestacaoInicial: simularEfetuarAcordo.data.dataPrestacaoInicial,
				dataPrestacaoFinal: simularEfetuarAcordo.data.dataPrestacaoFinal,
			})
			setContRequestAcordo(1+contRequestAcordo)
		}
	}, [hasSubmit, simularEfetuarAcordo, history, contRequestAcordo]);

	const handleEfetivar = useCallback(() => {
		if (
			gerarOcorrencia.data &&
			consultarMutuario.data &&
			simularEfetuarAcordo.data
		) {
			const { ocorrenciaId } = gerarOcorrencia.data;
			const { numeroContrato } = consultarMutuario.data;
			const { acordoId } = simularEfetuarAcordo.data;

			dispatch(
				simularEfetuarAcordoRequest(token, {
					servicoEnum: 'EFETUAR_ACORDO',
					acordoId,
					ocorrenciaId,
					contratoCDHU: Number(numeroContrato),
					tipoSimulacao: 1,
				}),
			);
			setHasSubmit(true);
		}
	}, [
		gerarOcorrencia.data,
		consultarMutuario.data,
		simularEfetuarAcordo.data,
		dispatch,
		token,
	]);

	return (
		<>
			{dataAcordoPadrao && (
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Section size="lg" title="Acordo padrão (Calculado)" titleSize="sm">
							<Row align="middle" justify="center">
								<Col span={12}>
									<Row gutter={[0, 5]}>
										<Col span={17}>Quantidade de parcelas do acordo:</Col>

										<Col span={6} offset={1}>
											<b>{dataAcordoPadrao.qtdParcelasAcordo}</b>
										</Col>
									</Row>

									<Row gutter={[0, 5]}>
										<Col span={17}>Valor da parcela de entrada do acordo:</Col>

										<Col span={6} offset={1}>
											<b>
												{formatCurrency(
													dataAcordoPadrao.valorParcelaInicial,
												)}
											</b>
										</Col>
									</Row>

									<Row gutter={[0, 5]}>
										<Col span={17}>Valor da parcela do acordo:</Col>

										<Col span={6} offset={1}>
											<b>
												{formatCurrency(
													dataAcordoPadrao.valorParcelaAcordo,
												)}
											</b>
										</Col>
									</Row>

									<Row gutter={[0, 5]}>
										<Col span={17}>
											Valor da prestação com a parcela do acordo:
										</Col>

										<Col span={6} offset={1}>
											<b>
												{formatCurrency(
													dataAcordoPadrao.novaPrestacaoLiquida,
												)}
											</b>
										</Col>
									</Row>

									<Row gutter={[0, 5]}>
										<Col span={17}>Data de vencimento da 1ª parcela:</Col>

										<Col span={6} offset={1}>
											<b>
												{formatDate(
													dataAcordoPadrao.dataPrestacaoInicial,
												)}
											</b>
										</Col>
									</Row>

									<Row>
										<Col span={17}>Data de vencimento da última parcela:</Col>

										<Col span={6} offset={1}>
											<b>
												{formatDate(
													dataAcordoPadrao.dataPrestacaoFinal,
												)}
											</b>
										</Col>
									</Row>
								</Col>

								<Col span={7} offset={1}>
									<Row justify="center">
										<Col>
											<ButtonImage src="efetivar" onClick={handleEfetivar} />
										</Col>
									</Row>
								</Col>
							</Row>
						</Section>
					</Col>
				</Row>
			)}
		</>
	);
};

export default AcordoPadrao;
